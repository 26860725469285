export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Node',
        possibleTypes: [
          {
            name: 'DailyCheckin',
          },
          {
            name: 'CoursewareStudySection',
          },
          {
            name: 'CoursewareLesson',
          },
          {
            name: 'Level',
          },
          {
            name: 'CoursewareCourse',
          },
          {
            name: 'VocabLocation',
          },
          {
            name: 'DictionarySense',
          },
          {
            name: 'SmartCourseLesson',
          },
          {
            name: 'SmartCourse',
          },
          {
            name: 'SmartCourseSection',
          },
          {
            name: 'ReviewLesson',
          },
          {
            name: 'ReviewNote',
          },
          {
            name: 'ReviewNotePracticeStudySection',
          },
          {
            name: 'ReviewNotebook',
          },
          {
            name: 'Roleplay',
          },
          {
            name: 'ClassTopic',
          },
          {
            name: 'ClassBooking',
          },
          {
            name: 'ClassBookingNote',
          },
          {
            name: 'User',
          },
          {
            name: 'StudySession',
          },
          {
            name: 'PracticeChat',
          },
          {
            name: 'StudySetChat',
          },
          {
            name: 'StudySet',
          },
          {
            name: 'SmartCourseCollection',
          },
          {
            name: 'LearningPathCollection',
          },
          {
            name: 'LearningPath',
          },
          {
            name: 'RoleplayCollection',
          },
          {
            name: 'RoleplayPlaylistCollection',
          },
          {
            name: 'RoleplayPlaylist',
          },
          {
            name: 'Story',
          },
          {
            name: 'Gif',
          },
          {
            name: 'AssistantChat',
          },
          {
            name: 'BugReportingChat',
          },
          {
            name: 'CoursewareChat',
          },
          {
            name: 'FeedbackChat',
          },
          {
            name: 'LessonChat',
          },
          {
            name: 'PremiumSampleChat',
          },
          {
            name: 'TeacherChat',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'StudySection',
        possibleTypes: [
          {
            name: 'CoursewareStudySection',
          },
          {
            name: 'SmartCourseSectionContent',
          },
          {
            name: 'ReviewNotePracticeStudySection',
          },
          {
            name: 'ShowcaseStudySection',
          },
          {
            name: 'BaseStudySection',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DialogSegment',
        possibleTypes: [
          {
            name: 'DialogBaseSegment',
          },
          {
            name: 'DialogPaywallSegment',
          },
          {
            name: 'DialogQuestionSegment',
          },
          {
            name: 'DialogTopicSegment',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'MessageItem',
        possibleTypes: [
          {
            name: 'TextMessageItem',
          },
          {
            name: 'GifMessageItem',
          },
          {
            name: 'AudioMessageItem',
          },
          {
            name: 'AudioCarouselMessageItem',
          },
          {
            name: 'VideoMessageItem',
          },
          {
            name: 'WebLinkMessageItem',
          },
          {
            name: 'ImageMessageItem',
          },
          {
            name: 'CardMessageItem',
          },
          {
            name: 'TextCardMessageItem',
          },
          {
            name: 'CardCarouselMessageItem',
          },
          {
            name: 'ReadingMessageItem',
          },
          {
            name: 'TableMessageItem',
          },
          {
            name: 'ImageAudioCardMessageItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CardMessageItemBodyItem',
        possibleTypes: [
          {
            name: 'CardMessageItemBodyImage',
          },
          {
            name: 'CardMessageItemBodyText',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CardCarouselMessageItemItem',
        possibleTypes: [
          {
            name: 'CardMessageItem',
          },
          {
            name: 'TextCardMessageItem',
          },
          {
            name: 'ImageAudioCardMessageItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CoursewareReviewSummaryContentItem',
        possibleTypes: [
          {
            name: 'ReviewTopic',
          },
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'TextCardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
          {
            name: 'ReadingReviewContentItem',
          },
          {
            name: 'TableReviewContentItem',
          },
          {
            name: 'ImageAudioCardReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewTopicBodyItem',
        possibleTypes: [
          {
            name: 'ReviewExample',
          },
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'TextCardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
          {
            name: 'ReadingReviewContentItem',
          },
          {
            name: 'TableReviewContentItem',
          },
          {
            name: 'ImageAudioCardReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewContentItem',
        possibleTypes: [
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'TextCardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
          {
            name: 'ReadingReviewContentItem',
          },
          {
            name: 'TableReviewContentItem',
          },
          {
            name: 'ImageAudioCardReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CardReviewContentItemBodyItem',
        possibleTypes: [
          {
            name: 'CardReviewContentItemBodyImage',
          },
          {
            name: 'CardReviewContentItemBodyText',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'CardCarouselReviewContentItemItem',
        possibleTypes: [
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'TextCardReviewContentItem',
          },
          {
            name: 'ImageAudioCardReviewContentItem',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DictionaryEnhancementInterface',
        possibleTypes: [
          {
            name: 'TranslationsDictionaryEnhancement',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SmartCourseReviewSummaryContentItem',
        possibleTypes: [
          {
            name: 'ReviewTopic',
          },
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'TextCardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
          {
            name: 'ReadingReviewContentItem',
          },
          {
            name: 'TableReviewContentItem',
          },
          {
            name: 'ImageAudioCardReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewNoteContentItem',
        possibleTypes: [
          {
            name: 'ReviewTopic',
          },
          {
            name: 'TextReviewContentItem',
          },
          {
            name: 'GifReviewContentItem',
          },
          {
            name: 'AudioReviewContentItem',
          },
          {
            name: 'AudioCarouselReviewContentItem',
          },
          {
            name: 'WebLinkReviewContentItem',
          },
          {
            name: 'VideoReviewContentItem',
          },
          {
            name: 'ImageReviewContentItem',
          },
          {
            name: 'CardReviewContentItem',
          },
          {
            name: 'TextCardReviewContentItem',
          },
          {
            name: 'CardCarouselReviewContentItem',
          },
          {
            name: 'ReadingReviewContentItem',
          },
          {
            name: 'TableReviewContentItem',
          },
          {
            name: 'ImageAudioCardReviewContentItem',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewNoteEncounterBodyItem',
        possibleTypes: [
          {
            name: 'ReviewSpeakingEncounter',
          },
          {
            name: 'ReviewMultipleChoiceTextEncounter',
          },
          {
            name: 'ReviewEncounterGroup',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ReviewEncounter',
        possibleTypes: [
          {
            name: 'ReviewSpeakingEncounter',
          },
          {
            name: 'ReviewMultipleChoiceTextEncounter',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Chat',
        possibleTypes: [
          {
            name: 'PracticeChat',
          },
          {
            name: 'StudySetChat',
          },
          {
            name: 'AssistantChat',
          },
          {
            name: 'BugReportingChat',
          },
          {
            name: 'CoursewareChat',
          },
          {
            name: 'FeedbackChat',
          },
          {
            name: 'LessonChat',
          },
          {
            name: 'PremiumSampleChat',
          },
          {
            name: 'TeacherChat',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PremiumPricePlan',
        possibleTypes: [
          {
            name: 'PremiumPricePlanAppStore',
          },
          {
            name: 'PremiumPricePlanGooglePlay',
          },
          {
            name: 'PremiumPricePlanVoucher',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'PromoPlan',
        possibleTypes: [
          {
            name: 'PromoPlanStripe',
          },
          {
            name: 'PromoPlanStripeFlat',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'StudySessionSectionContent',
        possibleTypes: [
          {
            name: 'BaseStudySessionSectionContent',
          },
          {
            name: 'CoursewareStudySessionSectionContent',
          },
          {
            name: 'SmartCourseStudySessionSectionContent',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'RoleplayLibraryItem',
        possibleTypes: [
          {
            name: 'RoleplayCollection',
          },
          {
            name: 'RoleplayPlaylistCollection',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'DailyPlanActivity',
        possibleTypes: [
          {
            name: 'SmartCourseSection',
          },
          {
            name: 'Roleplay',
          },
          {
            name: 'DailyPlanCertificate',
          },
          {
            name: 'PronunciationReview',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'ShowcaseSection',
        possibleTypes: [
          {
            name: 'ShowcaseStudySection',
          },
          {
            name: 'ShowcaseRoleplaySection',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'UserAsset',
        possibleTypes: [
          {
            name: 'AudioUserAsset',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'DialogQuestion',
        possibleTypes: [
          {
            name: 'DialogMultipleChoiceTextQuestion',
          },
          {
            name: 'DialogScrambleQuestion',
          },
          {
            name: 'DialogSpeakingQuestion',
          },
        ],
      },
    ],
  },
};
export default result;
