export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   **/
  Date: any;
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: any;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   **/
  DateTime: any;
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   **/
  Duration: any;
  /** The `Dom` scalar type represents the [EF Carbon DOM](https://github.com/ef-carbon/dom) tree. */
  Dom: any;
}

export interface AddStudyLanguageInput {
  language: StudyLanguage;
  level: UserLevelPreference;
  topic?: Maybe<SmartCourseTopicPreference>;
  sectionType?: Maybe<SmartCourseSectionTypePreference>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AddStudyLanguagePayload {
  __typename?: 'AddStudyLanguagePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface Admin {
  __typename?: 'Admin';
  /** The ID of an object */
  id: Scalars['ID'];
  email: Scalars['String'];
  role: AdminRole;
  permissions: Array<AdminPermission>;
}

export interface AdminAddCreditsInput {
  userId: Scalars['ID'];
  /** The amount of credits to give the user. A negative amount will result in removing credits from the user. */
  credits: Scalars['Int'];
  /** The reason for giving the user credits */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminAddCreditsPayload {
  __typename?: 'AdminAddCreditsPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminBookingsLookup {
  __typename?: 'AdminBookingsLookup';
  result: ClassBookingConnection;
}

export interface AdminCreatePromoCodeBatchInput {
  /** The product the voucher provides */
  productId: VoucherSubscriptionProduct;
  /** Name for the promo code */
  title: Scalars['String'];
  /** How many times the promo code can be used */
  maxRedemptions: Scalars['Int'];
  /** Why this promo code is created */
  reason: Scalars['String'];
  /** How many months until the code should expire */
  expiresInMonths: Scalars['Int'];
  /** How many codes to generate */
  amount: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreatePromoCodeBatchPayload {
  __typename?: 'AdminCreatePromoCodeBatchPayload';
  filename: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreatePromoCodeInput {
  /** The product the voucher provides */
  productId: PromoCodeProduct;
  /** Name for the promo code */
  title: Scalars['String'];
  /** How many times the promo code can be used */
  maxRedemptions: Scalars['Int'];
  /** Why this promo code is created */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreatePromoCodePayload {
  __typename?: 'AdminCreatePromoCodePayload';
  promoCode: PromoCode;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreateTranslationsDictionaryEnhancementInput {
  senseId: Scalars['ID'];
  source: DictionarySenseEnhancementSource;
  translations: Array<TranslationsMappingInput>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreateTranslationsDictionaryEnhancementPayload {
  __typename?: 'AdminCreateTranslationsDictionaryEnhancementPayload';
  word?: Maybe<DictionaryWord>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreateVocabLocationInput {
  word?: Maybe<Scalars['String']>;
  /** The dictionary sense ID */
  senseId: Scalars['ID'];
  /** The courseware lesson ID */
  lessonId: Scalars['ID'];
  isSupplemental?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminCreateVocabLocationPayload {
  __typename?: 'AdminCreateVocabLocationPayload';
  vocabLocation: VocabLocation;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminDeleteDictionaryEnhancementInput {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminDeleteDictionaryEnhancementPayload {
  __typename?: 'AdminDeleteDictionaryEnhancementPayload';
  sense?: Maybe<DictionarySense>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminDeleteVocabLocationInput {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminDeleteVocabLocationPayload {
  __typename?: 'AdminDeleteVocabLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminEmailLookup {
  __typename?: 'AdminEmailLookup';
  result?: Maybe<Admin>;
}

export interface AdminForgetUserInput {
  userId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminForgetUserPayload {
  __typename?: 'AdminForgetUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminLexicalaSearch {
  __typename?: 'AdminLexicalaSearch';
  results: Array<LexicalaWord>;
}

export interface AdminLockBucketAssignmentInput {
  userId: Scalars['ID'];
  bucketGroup: Scalars['String'];
  bucket: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminLockBucketAssignmentPayload {
  __typename?: 'AdminLockBucketAssignmentPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum AdminPermission {
  ManageAdmins = 'MANAGE_ADMINS',
  ManageClassBooking = 'MANAGE_CLASS_BOOKING',
  ManageDictionaryEnhancements = 'MANAGE_DICTIONARY_ENHANCEMENTS',
  ManageForgetUser = 'MANAGE_FORGET_USER',
  ManageUserBuckets = 'MANAGE_USER_BUCKETS',
  ManagePromoCodes = 'MANAGE_PROMO_CODES',
  ManageUserCredits = 'MANAGE_USER_CREDITS',
  ManageUserLevel = 'MANAGE_USER_LEVEL',
  ManageUserPremium = 'MANAGE_USER_PREMIUM',
  ManageUserLocalizedContentLanguage = 'MANAGE_USER_LOCALIZED_CONTENT_LANGUAGE',
  ManageUserEmail = 'MANAGE_USER_EMAIL',
  ManageVocab = 'MANAGE_VOCAB',
  ReadClassBooking = 'READ_CLASS_BOOKING',
  ReadCourseware = 'READ_COURSEWARE',
  ReadDictionary = 'READ_DICTIONARY',
  ReadDictionaryEnhancements = 'READ_DICTIONARY_ENHANCEMENTS',
  ReadSensitiveUserData = 'READ_SENSITIVE_USER_DATA',
  ReadUserData = 'READ_USER_DATA',
  ReadUserMessages = 'READ_USER_MESSAGES',
  ReadUserStats = 'READ_USER_STATS',
}

export interface AdminQuery {
  __typename?: 'AdminQuery';
  userEmailLookup?: Maybe<UserEmailLookup>;
  userIdLookup?: Maybe<UserIdLookup>;
  userPhoneLookup?: Maybe<UserPhoneLookup>;
  adminEmailLookup?: Maybe<AdminEmailLookup>;
  lessonProgress?: Maybe<LessonProgress>;
  adminBookingsLookup?: Maybe<AdminBookingsLookup>;
  /** All promo codes created by admins */
  adminPromoCodesLookup: PromoCodeConnection;
  adminLexicalaSearch?: Maybe<AdminLexicalaSearch>;
  /** Lookup a word in the dictionary */
  adminDictionaryLookup: Array<DictionaryWord>;
  /** Query to populate admin portal smart course */
  smartCourses?: Maybe<Array<SmartCourse>>;
}

export interface AdminQueryUserEmailLookupArgs {
  email: Scalars['String'];
}

export interface AdminQueryUserIdLookupArgs {
  userId: Scalars['String'];
}

export interface AdminQueryUserPhoneLookupArgs {
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
}

export interface AdminQueryAdminEmailLookupArgs {
  email: Scalars['String'];
}

export interface AdminQueryLessonProgressArgs {
  lessonId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface AdminQueryAdminBookingsLookupArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  startingAfter?: Maybe<Scalars['DateTime']>;
}

export interface AdminQueryAdminPromoCodesLookupArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sourceKind?: Maybe<PromoCodeSourceKind>;
}

export interface AdminQueryAdminLexicalaSearchArgs {
  word: Scalars['String'];
}

export interface AdminQueryAdminDictionaryLookupArgs {
  word: Scalars['String'];
  language?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithPhoneNumberInput {
  /** The promo code */
  code: Scalars['String'];
  /** The users phone number */
  phoneNumber: Scalars['String'];
  /** The users country code */
  countryCode: Scalars['String'];
  /** Why this promo code was applied to this user */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithPhoneNumberPayload {
  __typename?: 'AdminRedeemPromoCodeWithPhoneNumberPayload';
  user: User;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithUserIdInput {
  /** The promo code */
  code: Scalars['String'];
  /** The users ID */
  userId: Scalars['String'];
  /** Why this promo code was applied to this user */
  reason: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminRedeemPromoCodeWithUserIdPayload {
  __typename?: 'AdminRedeemPromoCodeWithUserIdPayload';
  user: User;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum AdminRole {
  FullAdmin = 'FULL_ADMIN',
  Teacher = 'TEACHER',
  HeadTeacher = 'HEAD_TEACHER',
  ContentCurator = 'CONTENT_CURATOR',
  Basic = 'BASIC',
  CustomerServiceAgent = 'CUSTOMER_SERVICE_AGENT',
}

export interface AdminSyncUserPremiumInput {
  userId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminSyncUserPremiumPayload {
  __typename?: 'AdminSyncUserPremiumPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminSyncUserRevenueCatInput {
  userId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminSyncUserRevenueCatPayload {
  __typename?: 'AdminSyncUserRevenueCatPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateAdminInput {
  adminId: Scalars['ID'];
  /** Updated admin role */
  role?: Maybe<AdminRole>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateAdminPayload {
  __typename?: 'AdminUpdateAdminPayload';
  admin?: Maybe<Admin>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdatePromoCodeInput {
  /** The promo code ID */
  id: Scalars['ID'];
  status?: Maybe<PromoCodeStatus>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdatePromoCodePayload {
  __typename?: 'AdminUpdatePromoCodePayload';
  promoCode: PromoCode;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserEmailInput {
  userId: Scalars['ID'];
  /** The new email for the user */
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserEmailPayload {
  __typename?: 'AdminUpdateUserEmailPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserLevelInput {
  userId: Scalars['ID'];
  /** The new level for the user */
  levelNumber: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserLevelPayload {
  __typename?: 'AdminUpdateUserLevelPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserLocalizedContentLanguageInput {
  userId: Scalars['ID'];
  language?: Maybe<SupportedLocalizedContentLanguageEnum>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpdateUserLocalizedContentLanguagePayload {
  __typename?: 'AdminUpdateUserLocalizedContentLanguagePayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpgradeUserInput {
  userId: Scalars['ID'];
  /** Voucher product id - this will determine the length of the premium upgrade */
  product: VoucherProduct;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AdminUpgradeUserPayload {
  __typename?: 'AdminUpgradeUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum AgeRange {
  Between_16_17 = 'BETWEEN_16_17',
  Between_18_24 = 'BETWEEN_18_24',
  Between_25_34 = 'BETWEEN_25_34',
  Between_35_44 = 'BETWEEN_35_44',
  Between_45_60 = 'BETWEEN_45_60',
  Over_60 = 'OVER_60',
}

export interface AiOnboardingResponseOption {
  __typename?: 'AIOnboardingResponseOption';
  /** The text of the response option */
  text: Scalars['String'];
  /** The localized text of the response option */
  localizedText: Scalars['String'];
}

export interface AppleIap {
  __typename?: 'AppleIAP';
  premiumPricePlans: Array<PremiumPricePlanAppStore>;
  offerPlan?: Maybe<PremiumPricePlanAppStore>;
}

export interface AskATeacherTicket {
  __typename?: 'AskATeacherTicket';
  /** The ID of an object */
  id: Scalars['ID'];
  sendbirdId: Scalars['Int'];
  sendbirdChannelUrl: Scalars['String'];
}

/** A chat for the user to interact with the assistant */
export interface AssistantChat extends Chat, Node {
  __typename?: 'AssistantChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  /** The routine for this assistant */
  routine: AssistantChatRoutine;
}

/** The routine of assistant chat */
export enum AssistantChatRoutine {
  /** Legacy assistant */
  Legacy = 'Legacy',
  /** An assistant to find language buddies for user */
  LanguageBuddy = 'LanguageBuddy',
}

export interface AudioCarouselMessageItem {
  __typename?: 'AudioCarouselMessageItem';
  items: Array<AudioMessageItem>;
}

export interface AudioCarouselReviewContentItem {
  __typename?: 'AudioCarouselReviewContentItem';
  items: Array<AudioReviewContentItem>;
}

export interface AudioMessageItem {
  __typename?: 'AudioMessageItem';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  transcript?: Maybe<Scalars['String']>;
  transcripts?: Maybe<Array<AudioMessageItemTranscriptItem>>;
  hideTranscripts?: Maybe<Scalars['Boolean']>;
}

export interface AudioMessageItemTranscriptItem {
  __typename?: 'AudioMessageItemTranscriptItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface AudioReviewContentItem {
  __typename?: 'AudioReviewContentItem';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  transcripts?: Maybe<Array<AudioReviewContentItemTranscript>>;
  hideTranscripts?: Maybe<Scalars['Boolean']>;
}

export interface AudioReviewContentItemTranscript {
  __typename?: 'AudioReviewContentItemTranscript';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface AudioUserAsset extends UserAsset {
  __typename?: 'AudioUserAsset';
  /** The ID of an object */
  id: Scalars['ID'];
  originalUrl?: Maybe<Scalars['String']>;
  processedUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface AuthAddSourceEmailInput {
  emailVerificationCode: Scalars['String'];
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthAddSourceEmailPayload {
  __typename?: 'AuthAddSourceEmailPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthAddSourceSocialInput {
  token: Scalars['String'];
  provider: SocialAuthProvider;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthAddSourceSocialPayload {
  __typename?: 'AuthAddSourceSocialPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthCheckAccountStatusEmailInput {
  email: Scalars['String'];
  code: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthCheckAccountStatusEmailPayload {
  __typename?: 'AuthCheckAccountStatusEmailPayload';
  isExistingAccount: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthCheckAccountStatusPhoneInput {
  phoneNumber: Scalars['String'];
  countryCode: Scalars['String'];
  code: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthCheckAccountStatusPhonePayload {
  __typename?: 'AuthCheckAccountStatusPhonePayload';
  isExistingAccount: Scalars['Boolean'];
  phoneVerificationToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthCheckAccountStatusSocialInput {
  provider: SocialAuthProvider;
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthCheckAccountStatusSocialPayload {
  __typename?: 'AuthCheckAccountStatusSocialPayload';
  isExistingAccount: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthExchangeLegacyTokenForRefreshTokenInput {
  legacyToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthExchangeLegacyTokenForRefreshTokenPayload {
  __typename?: 'AuthExchangeLegacyTokenForRefreshTokenPayload';
  viewer: Viewer;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountEmailInput {
  code: Scalars['String'];
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountEmailPayload {
  __typename?: 'AuthLoginAccountEmailPayload';
  viewer: Viewer;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountMagicTokenInput {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountMagicTokenPayload {
  __typename?: 'AuthLoginAccountMagicTokenPayload';
  viewer: Viewer;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountPhoneInput {
  phoneVerificationToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountPhonePayload {
  __typename?: 'AuthLoginAccountPhonePayload';
  viewer: Viewer;
  authToken: Scalars['String'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountSocialInput {
  token: Scalars['String'];
  provider: SocialAuthProvider;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLoginAccountSocialPayload {
  __typename?: 'AuthLoginAccountSocialPayload';
  viewer: Viewer;
  authToken: Scalars['String'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLogoutAccountInput {
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthLogoutAccountPayload {
  __typename?: 'AuthLogoutAccountPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRefreshAccessTokenInput {
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRefreshAccessTokenPayload {
  __typename?: 'AuthRefreshAccessTokenPayload';
  viewer: Viewer;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRegisterAccountEmailInput {
  emailVerificationCode: Scalars['String'];
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  utm?: Maybe<UtmParameters>;
  referrer?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRegisterAccountEmailPayload {
  __typename?: 'AuthRegisterAccountEmailPayload';
  viewer: Viewer;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRegisterAccountPhoneInput {
  phoneVerificationToken: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  utm?: Maybe<UtmParameters>;
  referrer?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRegisterAccountPhonePayload {
  __typename?: 'AuthRegisterAccountPhonePayload';
  viewer: Viewer;
  authToken: Scalars['String'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRegisterAccountSocialInput {
  token: Scalars['String'];
  provider: SocialAuthProvider;
  name?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  utm?: Maybe<UtmParameters>;
  referrer?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthRegisterAccountSocialPayload {
  __typename?: 'AuthRegisterAccountSocialPayload';
  viewer: Viewer;
  authToken: Scalars['String'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthSendEmailVerificationCodeInput {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthSendEmailVerificationCodePayload {
  __typename?: 'AuthSendEmailVerificationCodePayload';
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthSendPhoneVerificationCodeInput {
  phoneNumber: Scalars['String'];
  countryCode: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface AuthSendPhoneVerificationCodePayload {
  __typename?: 'AuthSendPhoneVerificationCodePayload';
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum AuthSource {
  Apple = 'APPLE',
  Google = 'GOOGLE',
  Phone = 'PHONE',
  Email = 'EMAIL',
}

export interface Avatar {
  __typename?: 'Avatar';
  /** The ID of an object */
  id: Scalars['ID'];
  image: Image;
  category: AvatarCategory;
}

export interface AvatarCategory {
  __typename?: 'AvatarCategory';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
}

/** Information about the current backend server */
export interface BackendInfo {
  __typename?: 'BackendInfo';
  /** Full name of the server */
  fullName?: Maybe<Scalars['String']>;
  /** Enviroment the backend is running in */
  env?: Maybe<Scalars['String']>;
  /** Region the server is deployed */
  region?: Maybe<Scalars['String']>;
  /** Version of the formations */
  formationsVersion?: Maybe<Scalars['Int']>;
}

/** Upload a file by base64 encoding the content into a string */
export interface Base64Upload {
  /** Base64 encoded file */
  base64: Scalars['String'];
}

export interface BaseStudySection extends StudySection {
  __typename?: 'BaseStudySection';
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  color?: Maybe<Scalars['HexColorCode']>;
}

export interface BaseStudySessionSectionContent
  extends StudySessionSectionContent {
  __typename?: 'BaseStudySessionSectionContent';
  type: Scalars['String'];
}

export interface BookingTimeslot {
  __typename?: 'BookingTimeslot';
  token: Scalars['String'];
  startTime: Scalars['DateTime'];
  /** duration in minutes */
  duration: Scalars['Int'];
  /** duration in minutes */
  durationInMinutes: Scalars['Int'];
}

export interface BookmarkWordInput {
  word: Scalars['String'];
  /** if true, create the bookmark, else delete the bookmark */
  bookmark: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface BookmarkWordPayload {
  __typename?: 'BookmarkWordPayload';
  viewer: Viewer;
  dictionaryWord?: Maybe<DictionaryWord>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface BucketAssignment {
  __typename?: 'BucketAssignment';
  bucketGroup: Scalars['String'];
  bucket: Scalars['String'];
  availableBuckets: Array<Maybe<Scalars['String']>>;
}

/** A chat for the user to report problems with the app */
export interface BugReportingChat extends Chat, Node {
  __typename?: 'BugReportingChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

export enum CancellationReason {
  Usage = 'USAGE',
  Technical = 'TECHNICAL',
  Usability = 'USABILITY',
  Complexity = 'COMPLEXITY',
  Pace = 'PACE',
  Engagement = 'ENGAGEMENT',
  Tutorial = 'TUTORIAL',
  Price = 'PRICE',
  Competitor = 'COMPETITOR',
  Feature = 'FEATURE',
  TryingOut = 'TRYING_OUT',
}

export interface CardCarouselMessageItem {
  __typename?: 'CardCarouselMessageItem';
  items: Array<CardCarouselMessageItemItem>;
}

export type CardCarouselMessageItemItem =
  | CardMessageItem
  | TextCardMessageItem
  | ImageAudioCardMessageItem;

export interface CardCarouselReviewContentItem {
  __typename?: 'CardCarouselReviewContentItem';
  items: Array<CardCarouselReviewContentItemItem>;
}

export type CardCarouselReviewContentItemItem =
  | CardReviewContentItem
  | TextCardReviewContentItem
  | ImageAudioCardReviewContentItem;

export interface CardMessageItem {
  __typename?: 'CardMessageItem';
  title?: Maybe<CardMessageItemTitle>;
  body: Scalars['String'];
  items: Array<CardMessageItemBodyItem>;
  imageUrl?: Maybe<Scalars['String']>;
  image?: Maybe<CardMessageItemImage>;
  audio?: Maybe<CardMessageItemAudio>;
  subTitle?: Maybe<Scalars['String']>;
  subtitle?: Maybe<CardMessageItemSubtitle>;
}

export interface CardMessageItemAudio {
  __typename?: 'CardMessageItemAudio';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  transcripts?: Maybe<Array<CardMessageItemTranscriptItem>>;
}

export interface CardMessageItemBodyImage {
  __typename?: 'CardMessageItemBodyImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export type CardMessageItemBodyItem =
  | CardMessageItemBodyImage
  | CardMessageItemBodyText;

export interface CardMessageItemBodyText {
  __typename?: 'CardMessageItemBodyText';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardMessageItemImage {
  __typename?: 'CardMessageItemImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface CardMessageItemSubtitle {
  __typename?: 'CardMessageItemSubtitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardMessageItemTitle {
  __typename?: 'CardMessageItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardMessageItemTranscriptItem {
  __typename?: 'CardMessageItemTranscriptItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardReviewContentItem {
  __typename?: 'CardReviewContentItem';
  title: CardReviewContentItemTitle;
  subtitle?: Maybe<CardReviewContentItemSubtitle>;
  items: Array<CardReviewContentItemBodyItem>;
  image?: Maybe<CardReviewContentItemImage>;
  audio?: Maybe<CardReviewContentItemAudio>;
}

export interface CardReviewContentItemAudio {
  __typename?: 'CardReviewContentItemAudio';
  url: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
}

export interface CardReviewContentItemBodyImage {
  __typename?: 'CardReviewContentItemBodyImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export type CardReviewContentItemBodyItem =
  | CardReviewContentItemBodyImage
  | CardReviewContentItemBodyText;

export interface CardReviewContentItemBodyText {
  __typename?: 'CardReviewContentItemBodyText';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardReviewContentItemImage {
  __typename?: 'CardReviewContentItemImage';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface CardReviewContentItemSubtitle {
  __typename?: 'CardReviewContentItemSubtitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface CardReviewContentItemTitle {
  __typename?: 'CardReviewContentItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export enum CefrLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export interface ChangeCourseInput {
  courseId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ChangeCoursePayload {
  __typename?: 'ChangeCoursePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ChangeLevelOptionInput {
  levelOption: LevelOption;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ChangeLevelOptionPayload {
  __typename?: 'ChangeLevelOptionPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** A chat that will be displayed in the users list of chats */
export interface Chat {
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

/** The type of chat */
export enum ChatType {
  /** An assistant to help the user with tasks */
  Assistant = 'assistant',
  /** A lesson for the user to complete */
  Lesson = 'lesson',
  /** A lesson for the user to complete */
  Courseware = 'courseware',
  /** A chat to submit feedback on the app */
  Feedback = 'feedback',
  /** A chat for the user to report problems with the app */
  BugReporting = 'bugReporting',
  /** A chat between users */
  User = 'user',
  /** A chat following a dialog in a practice section */
  Practice = 'practice',
  /** A chat showing a sample of a premium chat */
  PremiumSample = 'premiumSample',
  /** A chat the teacher chats */
  Teacher = 'teacher',
  /** A chat that allows users to study a study set */
  StudySet = 'studySet',
}

export interface ClassBooking extends Node {
  __typename?: 'ClassBooking';
  /** The ID of an object */
  id: Scalars['ID'];
  bookingTimeslot: BookingTimeslot;
  status: ClassBookingStatus;
  classTopic?: Maybe<ClassTopic>;
  channelIdentifier?: Maybe<Scalars['String']>;
  notes: Array<ClassBookingNote>;
  hasUnreadNotes: Scalars['Boolean'];
  user?: Maybe<User>;
  created: Scalars['DateTime'];
  teacher?: Maybe<Admin>;
}

/** A connection to a list of items. */
export interface ClassBookingConnection {
  __typename?: 'ClassBookingConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClassBookingEdge>>>;
}

/** An edge in a connection. */
export interface ClassBookingEdge {
  __typename?: 'ClassBookingEdge';
  /** The item at the end of the edge */
  node?: Maybe<ClassBooking>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface ClassBookingNote extends Node {
  __typename?: 'ClassBookingNote';
  /** The ID of an object */
  id: Scalars['ID'];
  note: Scalars['String'];
}

export enum ClassBookingStatus {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
}

export interface ClassTopic extends Node {
  __typename?: 'ClassTopic';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  lesson?: Maybe<CoursewareLesson>;
  deckEmbedUrl?: Maybe<Scalars['String']>;
  availableBookingTimeslots: Array<BookingTimeslot>;
}

/** The client being run by the user */
export enum Client {
  /** Apple mobile operating system */
  Ios = 'ios',
  /** Android mobile operating system */
  Android = 'android',
}

/** Information about potential updates to the client */
export interface ClientUpdate {
  __typename?: 'ClientUpdate';
  /** The type of update available to the user */
  updateType?: Maybe<UpdateType>;
  /** Optional url to download the update */
  url?: Maybe<Scalars['String']>;
}

export interface CompleteAiOnboardingInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CompleteAiOnboardingPayload {
  __typename?: 'CompleteAIOnboardingPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CompleteStudySessionInput {
  studySessionId: Scalars['ID'];
  points: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CompleteStudySessionPayload {
  __typename?: 'CompleteStudySessionPayload';
  viewer: Viewer;
  studySession: StudySession;
  /** Returns a daily checkin object if a new checkin is created, and null if not */
  checkin?: Maybe<DailyCheckin>;
  reviewNote?: Maybe<ReviewNote>;
  sectionProgress?: Maybe<SectionProgress>;
  generatedStudySetChat?: Maybe<StudySetChat>;
  smartCourseSection?: Maybe<SmartCourseSection>;
  completedUserPath?: Maybe<UserPath>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ContinueRoleplaySessionInput {
  roleplaySessionId: Scalars['ID'];
  message: Scalars['String'];
  userAssetId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ContinueRoleplaySessionPayload {
  __typename?: 'ContinueRoleplaySessionPayload';
  message: Scalars['String'];
  end?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum CourseDifficulty {
  None = 'NONE',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

/** A users progression inside a course */
export interface CourseProgress {
  __typename?: 'CourseProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  course: CoursewareCourse;
  /** The section the user would be on if they began studying this course. It will be null if the user has completed the course. */
  currentSection?: Maybe<CoursewareStudySection>;
}

/** A type of chat that the user should interact with a bot to learn English */
export interface CoursewareChat extends Chat, Node {
  __typename?: 'CoursewareChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  intro?: Maybe<Dialog>;
  botType: Scalars['String'];
  paywallUnlockTime?: Maybe<Scalars['DateTime']>;
}

/** A type of chat that the user should interact with a bot to learn English */
export interface CoursewareChatIntroArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

/** Main courseware */
export interface CoursewareConfig {
  __typename?: 'CoursewareConfig';
  /** Version of the courseware formations */
  currentFormationsVersion: Scalars['Int'];
}

export interface CoursewareCourse extends Node {
  __typename?: 'CoursewareCourse';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  difficulty: CourseDifficulty;
  cefrLevel: CefrLevel;
  lessons: Array<CoursewareLesson>;
  /** The number of lessons in the course */
  lessonsLength: Scalars['Int'];
}

export interface CoursewareLesson extends Node {
  __typename?: 'CoursewareLesson';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  /** The number of the lesson */
  number: Scalars['Int'];
  /** The number of the lesson within its course */
  numberInCourse: Scalars['Int'];
  level: Level;
  course: CoursewareCourse;
  /** The number of sections in the lesson */
  sectionsLength: Scalars['Int'];
  sections: Array<CoursewareStudySection>;
  vocabSuggestions?: Maybe<Array<Scalars['String']>>;
  vocabLocations?: Maybe<Array<VocabLocation>>;
}

export type CoursewareReviewSummaryContentItem =
  | ReviewTopic
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | TextCardReviewContentItem
  | CardCarouselReviewContentItem
  | ReadingReviewContentItem
  | TableReviewContentItem
  | ImageAudioCardReviewContentItem;

export interface CoursewareSectionReview {
  __typename?: 'CoursewareSectionReview';
  /** The ID of an object */
  id: Scalars['ID'];
  chat?: Maybe<PracticeChat>;
  totalReviewableSectionsStudied?: Maybe<Scalars['Int']>;
}

export interface CoursewareSectionReviewTotalReviewableSectionsStudiedArgs {
  since?: Maybe<Scalars['DateTime']>;
}

export interface CoursewareStudySection extends Node, StudySection {
  __typename?: 'CoursewareStudySection';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The number of the section */
  number: Scalars['Int'];
  dialog: Dialog;
  reviewSummaryContents: Array<CoursewareReviewSummaryContentItem>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sectionType: CoursewareStudySectionType;
  icon?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['HexColorCode']>;
  token: Scalars['String'];
  lesson: CoursewareLesson;
  vocabSuggestions?: Maybe<Array<Scalars['String']>>;
}

export enum CoursewareStudySectionType {
  Grammar = 'GRAMMAR',
  Speaking = 'SPEAKING',
  Listening = 'LISTENING',
  Vocabulary = 'VOCABULARY',
  Reading = 'READING',
  Expressions = 'EXPRESSIONS',
  Quiz = 'QUIZ',
  Skills = 'SKILLS',
  FinalTask = 'FINAL_TASK',
}

export interface CoursewareStudySessionSectionContent
  extends StudySessionSectionContent {
  __typename?: 'CoursewareStudySessionSectionContent';
  type: Scalars['String'];
  section?: Maybe<SmartCourseSection>;
}

export interface CreateRoleplaySessionInput {
  roleplayId: Scalars['ID'];
  sessionType?: Maybe<RoleplaySessionType>;
  /** Tags for the session */
  tags?: Maybe<Array<Scalars['String']>>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateRoleplaySessionPayload {
  __typename?: 'CreateRoleplaySessionPayload';
  roleplaySession: RoleplaySession;
  startSentence: Scalars['String'];
  warblerAuthToken?: Maybe<Scalars['String']>;
  vapiAssistantId?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateSendbirdSessionInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateSendbirdSessionPayload {
  __typename?: 'CreateSendbirdSessionPayload';
  viewer: Viewer;
  token: Scalars['String'];
  expires: Scalars['DateTime'];
  sendbirdUserId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStripeCheckoutSessionInput {
  supportedCurrency?: Maybe<SupportedCurrency>;
  planId: Scalars['ID'];
  promoCodeId?: Maybe<Scalars['ID']>;
  successUrl: Scalars['String'];
  failureUrl: Scalars['String'];
  utm?: Maybe<UtmParameters>;
  referrer?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStripeCheckoutSessionPayload {
  __typename?: 'CreateStripeCheckoutSessionPayload';
  checkoutSession: StripeCheckoutSession;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStripePortalSessionInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStripePortalSessionPayload {
  __typename?: 'CreateStripePortalSessionPayload';
  portalSessionUrl: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStudySetInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface CreateStudySetPayload {
  __typename?: 'CreateStudySetPayload';
  viewer: Viewer;
  studySetChat: StudySetChat;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface DailyCheckin extends Node {
  __typename?: 'DailyCheckin';
  /** The ID of an object */
  id: Scalars['ID'];
  date: Scalars['Date'];
}

export interface DailyPlan {
  __typename?: 'DailyPlan';
  activities: Array<DailyPlanActivity>;
  date: Scalars['Date'];
  nextUpLesson?: Maybe<SmartCourseLesson>;
  userPath?: Maybe<UserPath>;
  intro: Scalars['String'];
}

export type DailyPlanActivity =
  | SmartCourseSection
  | Roleplay
  | DailyPlanCertificate
  | PronunciationReview;

export interface DailyPlanCertificate {
  __typename?: 'DailyPlanCertificate';
  type: DailyPlanCertificateType;
  course: SmartCourse;
  lesson: SmartCourseLesson;
  image?: Maybe<Image>;
}

export enum DailyPlanCertificateType {
  LessonComplete = 'LessonComplete',
  SmartCourseComplete = 'SmartCourseComplete',
  UserPathComplete = 'UserPathComplete',
}

export interface DailyRanking {
  __typename?: 'DailyRanking';
  position?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  table: Array<DailyRankingTableRow>;
  date: Scalars['String'];
}

export interface DailyRankingTableRow {
  __typename?: 'DailyRankingTableRow';
  points: Scalars['Int'];
  position: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Avatar>;
}

/** A delight to show the user */
export interface Delight {
  __typename?: 'Delight';
  /** A delight dom node. */
  node: Scalars['Dom'];
}

/** The kind of delight required by the client, expresses a mood */
export enum DelightKind {
  /** A success delight */
  Success = 'success',
  /** A failure delight */
  Failure = 'failure',
}

/** The type of delight the client would like to present to the user, e.g. video */
export enum DelightType {
  /** A Video */
  Video = 'video',
}

export interface Dialog {
  __typename?: 'Dialog';
  segments: Array<DialogSegment>;
}

export interface DialogBaseSegment extends DialogSegment {
  __typename?: 'DialogBaseSegment';
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
  title?: Maybe<DialogSegmentTitle>;
}

export interface DialogMultipleChoiceTextChoice {
  __typename?: 'DialogMultipleChoiceTextChoice';
  identifier?: Maybe<Scalars['String']>;
  text: Scalars['String'];
}

export interface DialogMultipleChoiceTextQuestion extends DialogQuestion {
  __typename?: 'DialogMultipleChoiceTextQuestion';
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
  choices: Array<DialogMultipleChoiceTextChoice>;
  correctChoiceIndex: Scalars['Int'];
  correctChoiceIndices: Array<Scalars['Int']>;
}

/** Deprecated */
export interface DialogPaywallSegment extends DialogSegment {
  __typename?: 'DialogPaywallSegment';
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
  title?: Maybe<DialogSegmentTitle>;
}

export interface DialogQuestion {
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
}

export interface DialogQuestionSegment extends DialogSegment {
  __typename?: 'DialogQuestionSegment';
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
  title?: Maybe<DialogSegmentTitle>;
  starters?: Maybe<Array<DialogStatement>>;
  questions: Array<DialogQuestion>;
}

export interface DialogScrambleQuestion extends DialogQuestion {
  __typename?: 'DialogScrambleQuestion';
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
  answer: Array<Scalars['String']>;
  options: Array<Scalars['String']>;
  separator?: Maybe<Scalars['String']>;
}

export interface DialogSegment {
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
  title?: Maybe<DialogSegmentTitle>;
}

export interface DialogSegmentConditional {
  __typename?: 'DialogSegmentConditional';
  statements: Array<DialogStatement>;
  choices: Array<DialogSegmentConditionalChoice>;
  name: Scalars['String'];
}

export enum DialogSegmentConditionalAction {
  Skip = 'SKIP',
  Play = 'PLAY',
}

export interface DialogSegmentConditionalChoice {
  __typename?: 'DialogSegmentConditionalChoice';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
  action: DialogSegmentConditionalAction;
}

export interface DialogSegmentTitle {
  __typename?: 'DialogSegmentTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface DialogSegmentTopic {
  __typename?: 'DialogSegmentTopic';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface DialogSpeakingContent {
  __typename?: 'DialogSpeakingContent';
  text: Scalars['String'];
}

export interface DialogSpeakingQuestion extends DialogQuestion {
  __typename?: 'DialogSpeakingQuestion';
  statements: Array<DialogStatement>;
  /** Number of attempts allowed for this question */
  maxAttempts?: Maybe<Scalars['Int']>;
  /** When this question is studied, send this token back to the server */
  studyToken?: Maybe<Scalars['String']>;
  content: DialogSpeakingContent;
}

export interface DialogStatement {
  __typename?: 'DialogStatement';
  identifier: Scalars['String'];
  item: MessageItem;
  pauseChoice?: Maybe<PauseChoiceTextOption>;
}

export interface DialogTopicSegment extends DialogSegment {
  __typename?: 'DialogTopicSegment';
  statements: Array<DialogStatement>;
  conditional?: Maybe<DialogSegmentConditional>;
  title?: Maybe<DialogSegmentTitle>;
  topic: DialogSegmentTopic;
}

/** Object containing localized and english definitions for the requested word, if any exists */
export interface DictionaryDefinitions {
  __typename?: 'DictionaryDefinitions';
  /** English definitions of the requested word */
  text: Array<Scalars['String']>;
  /** Localized definitions of the requested word */
  localizedText: Array<Scalars['String']>;
}

/** An interface for the enhancements added to a sense */
export interface DictionaryEnhancementInterface {
  /** The ID of an object */
  id: Scalars['ID'];
  source: DictionarySenseEnhancementSource;
}

export enum DictionaryLanguage {
  Af = 'AF',
  Ar = 'AR',
  Az = 'AZ',
  Bg = 'BG',
  Bn = 'BN',
  Bs = 'BS',
  Ca = 'CA',
  Cs = 'CS',
  Cy = 'CY',
  Da = 'DA',
  De = 'DE',
  El = 'EL',
  Es = 'ES',
  Et = 'ET',
  Fa = 'FA',
  Fi = 'FI',
  Fr = 'FR',
  Fy = 'FY',
  He = 'HE',
  Hi = 'HI',
  Ht = 'HT',
  Hr = 'HR',
  Hu = 'HU',
  Id = 'ID',
  Is = 'IS',
  It = 'IT',
  Ja = 'JA',
  Ko = 'KO',
  Lt = 'LT',
  Lv = 'LV',
  Ms = 'MS',
  Mt = 'MT',
  Mww = 'MWW',
  Nl = 'NL',
  No = 'NO',
  Pl = 'PL',
  Prs = 'PRS',
  Ps = 'PS',
  Pt = 'PT',
  PtBr = 'PT_BR',
  Ro = 'RO',
  Ru = 'RU',
  Sk = 'SK',
  Sl = 'SL',
  Sr = 'SR',
  Sv = 'SV',
  Sw = 'SW',
  Ta = 'TA',
  Th = 'TH',
  Tr = 'TR',
  Uk = 'UK',
  Ur = 'UR',
  Vi = 'VI',
  ZhHans = 'ZH_HANS',
  ZhHant = 'ZH_HANT',
}

/** An object containing the sense information of a dictionary word */
export interface DictionarySense extends Node {
  __typename?: 'DictionarySense';
  /** The ID of an object */
  id: Scalars['ID'];
  /** Definitions of the requested word */
  definition: Scalars['String'];
  word: DictionaryWord;
  /** Part-of-Speech for the word */
  pos?: Maybe<Scalars['String']>;
  /** The inflections for this sense */
  inflections?: Maybe<Array<DictionarySenseInflection>>;
  /** examples of the sense */
  examples?: Maybe<Array<DictionarySenseExample>>;
  /** translations of the sense */
  translations?: Maybe<Array<DictionaryTranslation>>;
  adminEnhancements: Array<DictionaryEnhancementInterface>;
}

export enum DictionarySenseEnhancementSource {
  Lexicala = 'LEXICALA',
}

/** Example sentences for a sense */
export interface DictionarySenseExample {
  __typename?: 'DictionarySenseExample';
  text: Scalars['String'];
}

/** Inflections such as plural, past tense, etc. */
export interface DictionarySenseInflection {
  __typename?: 'DictionarySenseInflection';
  text: Scalars['String'];
  type: Scalars['String'];
}

/** An object containing a translation of an english word */
export interface DictionaryTranslation {
  __typename?: 'DictionaryTranslation';
  /** The word that was translated. Note that it might be a different inflection from the requested word. */
  word: Scalars['String'];
  /** Target language `word` was translated to */
  language?: Maybe<DictionaryLanguage>;
  /** The translation of `word` */
  translation: Scalars['String'];
}

export interface DictionaryWord {
  __typename?: 'DictionaryWord';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The root word for the dictionary result. This might be a different form (inflection) of the requested word. */
  word: Scalars['String'];
  /** Localized and English definitions for the requested word */
  definitions: DictionaryDefinitions;
  /** List of senses for this word */
  senses: Array<DictionarySense>;
  /** A list of possible translations, ordered by likeliness */
  translations: Array<DictionaryTranslation>;
  /** List of senses for this word, with the definition being localized */
  localizedSenses: Array<DictionarySense>;
  /** The url for the audio for the requested word */
  audioUrl?: Maybe<Scalars['String']>;
  /** The pronunciation for the requested word */
  pronunciation?: Maybe<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
}

export enum ErrorReason {
  StripeCheckoutSessionAlreadyPremium = 'STRIPE_CHECKOUT_SESSION_ALREADY_PREMIUM',
  Unknown = 'UNKNOWN',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenInvalid = 'TOKEN_INVALID',
  TokenRevoked = 'TOKEN_REVOKED',
  CodeInvalid = 'CODE_INVALID',
  EmailAlreadyInUse = 'EMAIL_ALREADY_IN_USE',
  AiMessageContentViolation = 'AI_MESSAGE_CONTENT_VIOLATION',
  AiMessageContentViolationSevere = 'AI_MESSAGE_CONTENT_VIOLATION_SEVERE',
}

/** A chat for the user to submit feedback on the app */
export interface FeedbackChat extends Chat, Node {
  __typename?: 'FeedbackChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

export interface ForgetMeInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ForgetMePayload {
  __typename?: 'ForgetMePayload';
  userIsForgotten?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  NonBinary = 'NON_BINARY',
  Na = 'NA',
}

export interface GenerateCurrentCoursewareStudySectionInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GenerateCurrentCoursewareStudySectionPayload {
  __typename?: 'GenerateCurrentCoursewareStudySectionPayload';
  viewer: Viewer;
  studySection?: Maybe<CoursewareStudySection>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GeneratePracticeChatStudySectionInput {
  practiceChatId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GeneratePracticeChatStudySectionPayload {
  __typename?: 'GeneratePracticeChatStudySectionPayload';
  studySection?: Maybe<BaseStudySection>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GenerateStudySetChatStudySectionInput {
  studySetId?: Maybe<Scalars['ID']>;
  sectionType: StudySetSectionType;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GenerateStudySetChatStudySectionPayload {
  __typename?: 'GenerateStudySetChatStudySectionPayload';
  viewer: Viewer;
  studySection?: Maybe<BaseStudySection>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GetAiCoursewareIntroResponse {
  __typename?: 'GetAICoursewareIntroResponse';
  /** The introduction to courseware from the AI */
  intro: Scalars['String'];
}

export interface GetAiOnboardingFollowUpResponse {
  __typename?: 'GetAIOnboardingFollowUpResponse';
  /** The follow up message from the AI */
  followUp: Scalars['String'];
}

export interface GetAiOnboardingIntroResponse {
  __typename?: 'GetAIOnboardingIntroResponse';
  /** The introduction of the AI onboarding */
  intro?: Maybe<Scalars['String']>;
  responseOptions: Array<AiOnboardingResponseOption>;
}

export interface GetAiWelcomeBackMessageResponse {
  __typename?: 'GetAIWelcomeBackMessageResponse';
  /** The welcome back message from the AI */
  message?: Maybe<Scalars['String']>;
  gif?: Maybe<Gif>;
}

export interface GetExplanationResponse {
  __typename?: 'GetExplanationResponse';
  /** The explanation of the question */
  explanation?: Maybe<Scalars['String']>;
}

export interface GetHintResponse {
  __typename?: 'GetHintResponse';
  /** The hint of the question */
  hint?: Maybe<Scalars['String']>;
}

export interface GetMoreExamplesResponse {
  __typename?: 'GetMoreExamplesResponse';
  /** More examples of the given learning topic */
  examples?: Maybe<Scalars['String']>;
}

export interface GetRoleplaySuggestionsResponse {
  __typename?: 'GetRoleplaySuggestionsResponse';
  /** Suggestions for user to respond with in the roleplay session */
  suggestions?: Maybe<Array<RoleplaySuggestion>>;
}

export interface GetSummaryResponse {
  __typename?: 'GetSummaryResponse';
  /** Summary of the last teaching content. */
  summary?: Maybe<Scalars['String']>;
}

export interface Gif extends Node {
  __typename?: 'Gif';
  /** The ID of an object */
  id: Scalars['ID'];
  giphyId: Scalars['String'];
  type: GifType;
}

/** A connection to a list of items. */
export interface GifConnectionConnection {
  __typename?: 'GifConnectionConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GifConnectionEdge>>>;
}

/** An edge in a connection. */
export interface GifConnectionEdge {
  __typename?: 'GifConnectionEdge';
  /** The item at the end of the edge */
  node?: Maybe<Gif>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface GifMessageItem {
  __typename?: 'GifMessageItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface GifReviewContentItem {
  __typename?: 'GifReviewContentItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export enum GifType {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

/** Global configuration values */
export interface GlobalConfig {
  __typename?: 'GlobalConfig';
  /** Information about the backend */
  backendInfo: BackendInfo;
  /** Study Reminder feature */
  studyReminder?: Maybe<StudyReminderConfig>;
  /** Courseware */
  courseware?: Maybe<CoursewareConfig>;
}

export interface GoogleIap {
  __typename?: 'GoogleIAP';
  premiumPricePlans: Array<PremiumPricePlanGooglePlay>;
  offerPlan?: Maybe<PremiumPricePlanGooglePlay>;
}

export interface GradeRoleplaySessionInput {
  roleplaySessionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GradeRoleplaySessionPayload {
  __typename?: 'GradeRoleplaySessionPayload';
  viewer: Viewer;
  roleplaySession: RoleplaySession;
  roleplay: Roleplay;
  checkin?: Maybe<DailyCheckin>;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** An object with speech grading results for a given word  */
export interface GradeSpeechPhonemeGrade {
  __typename?: 'GradeSpeechPhonemeGrade';
  letters: Scalars['String'];
  score: Scalars['Float'];
  isCorrect: Scalars['Boolean'];
}

/** The calculated confidence, with potential additional data */
export interface GradeSpeechResult {
  __typename?: 'GradeSpeechResult';
  isCorrect: Scalars['Boolean'];
  /** score from 0 - 1 indicating how well the student did */
  score: Scalars['Float'];
  wordGrades?: Maybe<Array<GradeSpeechWordGrade>>;
  /** The user asset associated to the grade speech recording */
  userAsset?: Maybe<AudioUserAsset>;
}

/** An object with speech grading results for a given word  */
export interface GradeSpeechWordGrade {
  __typename?: 'GradeSpeechWordGrade';
  word: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  score: Scalars['Float'];
  phonemeGrades?: Maybe<Array<GradeSpeechPhonemeGrade>>;
}

export enum GradeState {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export interface Image {
  __typename?: 'Image';
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
}

export interface ImageAudioCardMessageItem {
  __typename?: 'ImageAudioCardMessageItem';
  image?: Maybe<CardMessageItemImage>;
  audio?: Maybe<CardMessageItemAudio>;
}

export interface ImageAudioCardReviewContentItem {
  __typename?: 'ImageAudioCardReviewContentItem';
  audio?: Maybe<CardReviewContentItemAudio>;
  image?: Maybe<CardReviewContentItemImage>;
}

export interface ImageMessageItem {
  __typename?: 'ImageMessageItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

export interface ImageReviewContentItem {
  __typename?: 'ImageReviewContentItem';
  url: Scalars['String'];
  aspect?: Maybe<Scalars['Float']>;
}

/** Internationalization information */
export interface Intl {
  __typename?: 'Intl';
  supportedLocalizedContentLanguages: Array<SupportedLocalizedContentLanguage>;
}

export interface LearningPath extends Node {
  __typename?: 'LearningPath';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  analyticsName: Scalars['String'];
  language: StudyLanguage;
  description: Scalars['String'];
  difficulty: SmartCourseDifficulty;
  duration: Scalars['Duration'];
  coursesLength: Scalars['Int'];
  courses: Array<Maybe<LearningPathCourse>>;
  cover?: Maybe<LearningPathCover>;
  following: Scalars['Boolean'];
  progress?: Maybe<LearningPathProgress>;
}

export interface LearningPathCollection extends Node {
  __typename?: 'LearningPathCollection';
  /** The ID of an object */
  id: Scalars['ID'];
  language: StudyLanguage;
  learningPaths: LearningPathConnection;
}

export interface LearningPathCollectionLearningPathsArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<LearningPathCollectionOrderBy>;
}

export enum LearningPathCollectionOrderBy {
  Default = 'DEFAULT',
  DifficultyAsc = 'DIFFICULTY_ASC',
}

/** A connection to a list of items. */
export interface LearningPathConnection {
  __typename?: 'LearningPathConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LearningPathEdge>>>;
}

export interface LearningPathCourse {
  __typename?: 'LearningPathCourse';
  description: Scalars['String'];
  smartCourse?: Maybe<SmartCourse>;
}

export interface LearningPathCover {
  __typename?: 'LearningPathCover';
  fromColor: Scalars['HexColorCode'];
  toColor: Scalars['HexColorCode'];
  url: Scalars['String'];
  title: Scalars['String'];
}

/** An edge in a connection. */
export interface LearningPathEdge {
  __typename?: 'LearningPathEdge';
  /** The item at the end of the edge */
  node?: Maybe<LearningPath>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface LearningPathFollowInput {
  learningPathId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface LearningPathFollowPayload {
  __typename?: 'LearningPathFollowPayload';
  learningPath: LearningPath;
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface LearningPathProgress {
  __typename?: 'LearningPathProgress';
  completedCourseCount: Scalars['Int'];
  isCompleted: Scalars['Boolean'];
}

/** A type of chat that the user should interact with a bot to learn English */
export interface LessonChat extends Chat, Node {
  __typename?: 'LessonChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  /** The id of the current lesson */
  lessonId?: Maybe<Scalars['String']>;
}

/** A users progression through the lesson */
export interface LessonProgress {
  __typename?: 'LessonProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  lesson: CoursewareLesson;
  /** The user ID the progress is for */
  userId?: Maybe<Scalars['String']>;
  /** The ID of the lesson */
  lessonId?: Maybe<Scalars['String']>;
  /** The title of the lesson */
  lessonTitle?: Maybe<Scalars['String']>;
  /** The current lesson ID */
  isCompleted: Scalars['Boolean'];
  /** When the lesson was started */
  startDate?: Maybe<Scalars['DateTime']>;
  /** When the lesson was completed */
  completedDate?: Maybe<Scalars['DateTime']>;
  /** The progression through the lesson sections */
  sectionsProgress?: Maybe<Array<SectionProgress>>;
  /** The points that the user has accumulated */
  points?: Maybe<Scalars['Int']>;
  /** The location of this lesson in the level lessons */
  levelProgress: LevelProgress;
  sectionTypeStats?: Maybe<Array<SectionTypeStat>>;
}

/** A connection to a list of items. */
export interface LessonProgressConnection {
  __typename?: 'LessonProgressConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LessonProgressEdge>>>;
}

/** An edge in a connection. */
export interface LessonProgressEdge {
  __typename?: 'LessonProgressEdge';
  /** The item at the end of the edge */
  node?: Maybe<LessonProgress>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface Level extends Node {
  __typename?: 'Level';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The number of the level */
  number: Scalars['Int'];
  /** Lesson contents */
  description?: Maybe<Scalars['String']>;
  /** Level of difficulty */
  difficulty: Scalars['String'];
  /** The number of lessons in the level */
  lessonsLength: Scalars['Int'];
  lessons: Array<CoursewareLesson>;
}

export enum LevelOption {
  None = 'NONE',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

export interface LevelOptionInformation {
  __typename?: 'LevelOptionInformation';
  levelOption: LevelOption;
  /** The starting level for this level option */
  fromLevelNumber: Scalars['Int'];
  /** The ending level for this level option */
  toLevelNumber: Scalars['Int'];
  /** The number of lessons in this level option */
  totalLessons?: Maybe<Scalars['Int']>;
  /** The level the user was last active on in this level option */
  lastActiveLevelNumber?: Maybe<Scalars['Int']>;
  /** The last active study section in this level option, or null if none */
  lastActiveLessonOffset?: Maybe<Scalars['Int']>;
  /** If this level option is joinable */
  isCompleted: Scalars['Boolean'];
}

/** A users progression inside a level */
export interface LevelProgress {
  __typename?: 'LevelProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  level: Level;
  /** The index of lessons in the level this lesson is at */
  idx?: Maybe<Scalars['Int']>;
  /** The number of lessons in the level that this lesson is in */
  length?: Maybe<Scalars['Int']>;
}

export interface LexicalaSense {
  __typename?: 'LexicalaSense';
  /** The ID of an object */
  id: Scalars['ID'];
  definition: Scalars['String'];
  examples?: Maybe<Array<Scalars['String']>>;
  translations?: Maybe<Array<LexicalaTranslation>>;
}

export interface LexicalaTranslation {
  __typename?: 'LexicalaTranslation';
  language: DictionaryLanguage;
  translation: Scalars['String'];
}

export interface LexicalaWord {
  __typename?: 'LexicalaWord';
  headword: Scalars['String'];
  /** part of speech */
  pos?: Maybe<Scalars['String']>;
  senses: Array<Maybe<LexicalaSense>>;
}

export interface LockInBucketAssignmentInput {
  bucketGroup: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface LockInBucketAssignmentPayload {
  __typename?: 'LockInBucketAssignmentPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export type MessageItem =
  | TextMessageItem
  | GifMessageItem
  | AudioMessageItem
  | AudioCarouselMessageItem
  | VideoMessageItem
  | WebLinkMessageItem
  | ImageMessageItem
  | CardMessageItem
  | TextCardMessageItem
  | CardCarouselMessageItem
  | ReadingMessageItem
  | TableMessageItem
  | ImageAudioCardMessageItem;

export interface Mutation {
  __typename?: 'Mutation';
  adminAddCredits?: Maybe<AdminAddCreditsPayload>;
  adminCreatePromoCode?: Maybe<AdminCreatePromoCodePayload>;
  adminCreatePromoCodeBatch?: Maybe<AdminCreatePromoCodeBatchPayload>;
  adminCreateTranslationsDictionaryEnhancement?: Maybe<
    AdminCreateTranslationsDictionaryEnhancementPayload
  >;
  adminCreateVocabLocation?: Maybe<AdminCreateVocabLocationPayload>;
  adminDeleteDictionaryEnhancement?: Maybe<
    AdminDeleteDictionaryEnhancementPayload
  >;
  adminDeleteVocabLocation?: Maybe<AdminDeleteVocabLocationPayload>;
  adminForgetUser?: Maybe<AdminForgetUserPayload>;
  adminRedeemPromoCodeWithPhoneNumber?: Maybe<
    AdminRedeemPromoCodeWithPhoneNumberPayload
  >;
  adminRedeemPromoCodeWithUserId?: Maybe<AdminRedeemPromoCodeWithUserIdPayload>;
  adminUpdateAdmin?: Maybe<AdminUpdateAdminPayload>;
  adminUpdatePromoCode?: Maybe<AdminUpdatePromoCodePayload>;
  adminUpdateUserLevel?: Maybe<AdminUpdateUserLevelPayload>;
  adminUpgradeUser?: Maybe<AdminUpgradeUserPayload>;
  adminLockBucketAssignment?: Maybe<AdminLockBucketAssignmentPayload>;
  adminSyncUserPremium?: Maybe<AdminSyncUserPremiumPayload>;
  adminSyncUserRevenueCat?: Maybe<AdminSyncUserRevenueCatPayload>;
  adminUpdateUserLocalizedContentLanguage?: Maybe<
    AdminUpdateUserLocalizedContentLanguagePayload
  >;
  adminUpdateUserEmail?: Maybe<AdminUpdateUserEmailPayload>;
  authCheckAccountStatusEmail?: Maybe<AuthCheckAccountStatusEmailPayload>;
  authCheckAccountStatusPhone?: Maybe<AuthCheckAccountStatusPhonePayload>;
  authCheckAccountStatusSocial?: Maybe<AuthCheckAccountStatusSocialPayload>;
  authExchangeLegacyTokenForRefreshToken?: Maybe<
    AuthExchangeLegacyTokenForRefreshTokenPayload
  >;
  authLoginAccountEmail?: Maybe<AuthLoginAccountEmailPayload>;
  authLoginAccountPhone?: Maybe<AuthLoginAccountPhonePayload>;
  authLoginAccountSocial?: Maybe<AuthLoginAccountSocialPayload>;
  authLoginAccountMagicToken?: Maybe<AuthLoginAccountMagicTokenPayload>;
  authLogoutAccount?: Maybe<AuthLogoutAccountPayload>;
  authRefreshAccessToken?: Maybe<AuthRefreshAccessTokenPayload>;
  authRegisterAccountEmail?: Maybe<AuthRegisterAccountEmailPayload>;
  authRegisterAccountPhone?: Maybe<AuthRegisterAccountPhonePayload>;
  authRegisterAccountSocial?: Maybe<AuthRegisterAccountSocialPayload>;
  authSendEmailVerificationCode?: Maybe<AuthSendEmailVerificationCodePayload>;
  authSendPhoneVerificationCode?: Maybe<AuthSendPhoneVerificationCodePayload>;
  authAddSourceEmail?: Maybe<AuthAddSourceEmailPayload>;
  authAddSourceSocial?: Maybe<AuthAddSourceSocialPayload>;
  smartCourseEnroll?: Maybe<SmartCourseEnrollPayload>;
  smartCourseEnrollInitialCourse?: Maybe<SmartCourseEnrollInitialCoursePayload>;
  smartCourseMigrate?: Maybe<SmartCourseMigratePayload>;
  smartCourseSubmitPreferences?: Maybe<SmartCourseSubmitPreferencesPayload>;
  smartCourseUnenroll?: Maybe<SmartCourseUnenrollPayload>;
  learningPathFollow?: Maybe<LearningPathFollowPayload>;
  bookmarkWord?: Maybe<BookmarkWordPayload>;
  changeCourse?: Maybe<ChangeCoursePayload>;
  changeLevelOption?: Maybe<ChangeLevelOptionPayload>;
  completeStudySession?: Maybe<CompleteStudySessionPayload>;
  createSendbirdSession?: Maybe<CreateSendbirdSessionPayload>;
  createStripeCheckoutSession?: Maybe<CreateStripeCheckoutSessionPayload>;
  createStripePortalSession?: Maybe<CreateStripePortalSessionPayload>;
  createStudySet?: Maybe<CreateStudySetPayload>;
  generateCurrentCoursewareStudySection?: Maybe<
    GenerateCurrentCoursewareStudySectionPayload
  >;
  generatePracticeChatStudySection?: Maybe<
    GeneratePracticeChatStudySectionPayload
  >;
  generateStudySetChatStudySection?: Maybe<
    GenerateStudySetChatStudySectionPayload
  >;
  lockInBucketAssignment?: Maybe<LockInBucketAssignmentPayload>;
  recordCancellationReason?: Maybe<RecordCancellationReasonPayload>;
  recordStudyResponse?: Maybe<RecordStudyResponsePayload>;
  /** Redeem a promo code */
  redeemPromoCode?: Maybe<RedeemPromoCodePayload>;
  /** Redeem a voucher code */
  redeemVoucher?: Maybe<RedeemVoucherPayload>;
  registerAccountAnonymous?: Maybe<RegisterAccountAnonymousPayload>;
  startStudySession?: Maybe<StartStudySessionPayload>;
  submitTeacherQuestion?: Maybe<SubmitTeacherQuestionPayload>;
  updateAccountInfo?: Maybe<UpdateAccountInfoPayload>;
  updateAnnotationForReviewNote?: Maybe<UpdateAnnotationForReviewNotePayload>;
  validatePurchase?: Maybe<ValidatePurchasePayload>;
  validateGooglePurchase?: Maybe<ValidateGooglePurchasePayload>;
  forgetMe?: Maybe<ForgetMePayload>;
  addStudyLanguage?: Maybe<AddStudyLanguagePayload>;
  createRoleplaySession?: Maybe<CreateRoleplaySessionPayload>;
  continueRoleplaySession?: Maybe<ContinueRoleplaySessionPayload>;
  textToSpeech?: Maybe<TextToSpeechPayload>;
  transcribeUserSpeech?: Maybe<TranscribeUserSpeechPayload>;
  gradeRoleplaySession?: Maybe<GradeRoleplaySessionPayload>;
  /** Complete AI onboarding */
  completeAIOnboarding?: Maybe<CompleteAiOnboardingPayload>;
  /** Refreshes the daily plan for the user, useful in development to test the daily plan */
  refreshDailyPlan?: Maybe<RefreshDailyPlanPayload>;
}

export interface MutationAdminAddCreditsArgs {
  input: AdminAddCreditsInput;
}

export interface MutationAdminCreatePromoCodeArgs {
  input: AdminCreatePromoCodeInput;
}

export interface MutationAdminCreatePromoCodeBatchArgs {
  input: AdminCreatePromoCodeBatchInput;
}

export interface MutationAdminCreateTranslationsDictionaryEnhancementArgs {
  input: AdminCreateTranslationsDictionaryEnhancementInput;
}

export interface MutationAdminCreateVocabLocationArgs {
  input: AdminCreateVocabLocationInput;
}

export interface MutationAdminDeleteDictionaryEnhancementArgs {
  input: AdminDeleteDictionaryEnhancementInput;
}

export interface MutationAdminDeleteVocabLocationArgs {
  input: AdminDeleteVocabLocationInput;
}

export interface MutationAdminForgetUserArgs {
  input: AdminForgetUserInput;
}

export interface MutationAdminRedeemPromoCodeWithPhoneNumberArgs {
  input: AdminRedeemPromoCodeWithPhoneNumberInput;
}

export interface MutationAdminRedeemPromoCodeWithUserIdArgs {
  input: AdminRedeemPromoCodeWithUserIdInput;
}

export interface MutationAdminUpdateAdminArgs {
  input: AdminUpdateAdminInput;
}

export interface MutationAdminUpdatePromoCodeArgs {
  input: AdminUpdatePromoCodeInput;
}

export interface MutationAdminUpdateUserLevelArgs {
  input: AdminUpdateUserLevelInput;
}

export interface MutationAdminUpgradeUserArgs {
  input: AdminUpgradeUserInput;
}

export interface MutationAdminLockBucketAssignmentArgs {
  input: AdminLockBucketAssignmentInput;
}

export interface MutationAdminSyncUserPremiumArgs {
  input: AdminSyncUserPremiumInput;
}

export interface MutationAdminSyncUserRevenueCatArgs {
  input: AdminSyncUserRevenueCatInput;
}

export interface MutationAdminUpdateUserLocalizedContentLanguageArgs {
  input: AdminUpdateUserLocalizedContentLanguageInput;
}

export interface MutationAdminUpdateUserEmailArgs {
  input: AdminUpdateUserEmailInput;
}

export interface MutationAuthCheckAccountStatusEmailArgs {
  input: AuthCheckAccountStatusEmailInput;
}

export interface MutationAuthCheckAccountStatusPhoneArgs {
  input: AuthCheckAccountStatusPhoneInput;
}

export interface MutationAuthCheckAccountStatusSocialArgs {
  input: AuthCheckAccountStatusSocialInput;
}

export interface MutationAuthExchangeLegacyTokenForRefreshTokenArgs {
  input: AuthExchangeLegacyTokenForRefreshTokenInput;
}

export interface MutationAuthLoginAccountEmailArgs {
  input: AuthLoginAccountEmailInput;
}

export interface MutationAuthLoginAccountPhoneArgs {
  input: AuthLoginAccountPhoneInput;
}

export interface MutationAuthLoginAccountSocialArgs {
  input: AuthLoginAccountSocialInput;
}

export interface MutationAuthLoginAccountMagicTokenArgs {
  input: AuthLoginAccountMagicTokenInput;
}

export interface MutationAuthLogoutAccountArgs {
  input: AuthLogoutAccountInput;
}

export interface MutationAuthRefreshAccessTokenArgs {
  input: AuthRefreshAccessTokenInput;
}

export interface MutationAuthRegisterAccountEmailArgs {
  input: AuthRegisterAccountEmailInput;
}

export interface MutationAuthRegisterAccountPhoneArgs {
  input: AuthRegisterAccountPhoneInput;
}

export interface MutationAuthRegisterAccountSocialArgs {
  input: AuthRegisterAccountSocialInput;
}

export interface MutationAuthSendEmailVerificationCodeArgs {
  input: AuthSendEmailVerificationCodeInput;
}

export interface MutationAuthSendPhoneVerificationCodeArgs {
  input: AuthSendPhoneVerificationCodeInput;
}

export interface MutationAuthAddSourceEmailArgs {
  input: AuthAddSourceEmailInput;
}

export interface MutationAuthAddSourceSocialArgs {
  input: AuthAddSourceSocialInput;
}

export interface MutationSmartCourseEnrollArgs {
  input: SmartCourseEnrollInput;
}

export interface MutationSmartCourseEnrollInitialCourseArgs {
  input: SmartCourseEnrollInitialCourseInput;
}

export interface MutationSmartCourseMigrateArgs {
  input: SmartCourseMigrateInput;
}

export interface MutationSmartCourseSubmitPreferencesArgs {
  input: SmartCourseSubmitPreferencesInput;
}

export interface MutationSmartCourseUnenrollArgs {
  input: SmartCourseUnenrollInput;
}

export interface MutationLearningPathFollowArgs {
  input: LearningPathFollowInput;
}

export interface MutationBookmarkWordArgs {
  input: BookmarkWordInput;
}

export interface MutationChangeCourseArgs {
  input: ChangeCourseInput;
}

export interface MutationChangeLevelOptionArgs {
  input: ChangeLevelOptionInput;
}

export interface MutationCompleteStudySessionArgs {
  input: CompleteStudySessionInput;
}

export interface MutationCreateSendbirdSessionArgs {
  input: CreateSendbirdSessionInput;
}

export interface MutationCreateStripeCheckoutSessionArgs {
  input: CreateStripeCheckoutSessionInput;
}

export interface MutationCreateStripePortalSessionArgs {
  input: CreateStripePortalSessionInput;
}

export interface MutationCreateStudySetArgs {
  input: CreateStudySetInput;
}

export interface MutationGenerateCurrentCoursewareStudySectionArgs {
  input: GenerateCurrentCoursewareStudySectionInput;
}

export interface MutationGeneratePracticeChatStudySectionArgs {
  input: GeneratePracticeChatStudySectionInput;
}

export interface MutationGenerateStudySetChatStudySectionArgs {
  input: GenerateStudySetChatStudySectionInput;
}

export interface MutationLockInBucketAssignmentArgs {
  input: LockInBucketAssignmentInput;
}

export interface MutationRecordCancellationReasonArgs {
  input: RecordCancellationReasonInput;
}

export interface MutationRecordStudyResponseArgs {
  input: RecordStudyResponseInput;
}

export interface MutationRedeemPromoCodeArgs {
  input: RedeemPromoCodeInput;
}

export interface MutationRedeemVoucherArgs {
  input: RedeemVoucherInput;
}

export interface MutationRegisterAccountAnonymousArgs {
  input: RegisterAccountAnonymousInput;
}

export interface MutationStartStudySessionArgs {
  input: StartStudySessionInput;
}

export interface MutationSubmitTeacherQuestionArgs {
  input: SubmitTeacherQuestionInput;
}

export interface MutationUpdateAccountInfoArgs {
  input: UpdateAccountInfoInput;
}

export interface MutationUpdateAnnotationForReviewNoteArgs {
  input: UpdateAnnotationForReviewNoteInput;
}

export interface MutationValidatePurchaseArgs {
  input: ValidatePurchaseInput;
}

export interface MutationValidateGooglePurchaseArgs {
  input: ValidateGooglePurchaseInput;
}

export interface MutationForgetMeArgs {
  input: ForgetMeInput;
}

export interface MutationAddStudyLanguageArgs {
  input: AddStudyLanguageInput;
}

export interface MutationCreateRoleplaySessionArgs {
  input: CreateRoleplaySessionInput;
}

export interface MutationContinueRoleplaySessionArgs {
  input: ContinueRoleplaySessionInput;
}

export interface MutationTextToSpeechArgs {
  input: TextToSpeechInput;
}

export interface MutationTranscribeUserSpeechArgs {
  input: TranscribeUserSpeechInput;
}

export interface MutationGradeRoleplaySessionArgs {
  input: GradeRoleplaySessionInput;
}

export interface MutationCompleteAiOnboardingArgs {
  input: CompleteAiOnboardingInput;
}

export interface MutationRefreshDailyPlanArgs {
  input: RefreshDailyPlanInput;
}

/** An object with an ID */
export interface Node {
  /** The id of the object. */
  id: Scalars['ID'];
}

export interface NotesReview {
  __typename?: 'NotesReview';
  /** The ID of an object */
  id: Scalars['ID'];
  totalNotes?: Maybe<Scalars['Int']>;
}

export interface NotesReviewTotalNotesArgs {
  since?: Maybe<Scalars['DateTime']>;
}

/** Information about pagination in a connection. */
export interface PageInfo {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
}

export interface PauseChoiceTextOption {
  __typename?: 'PauseChoiceTextOption';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface PaymentProvider {
  __typename?: 'PaymentProvider';
  appleIAP?: Maybe<AppleIap>;
  googleIAP?: Maybe<GoogleIap>;
  voucherIAP?: Maybe<VoucherIap>;
  stripeCheckout?: Maybe<StripeCheckout>;
}

export enum PaymentProviderName {
  AppleIapSubscription = 'APPLE_IAP_SUBSCRIPTION',
  GoogleIapSubscription = 'GOOGLE_IAP_SUBSCRIPTION',
  PremiumVoucher = 'PREMIUM_VOUCHER',
  StripeSubscription = 'STRIPE_SUBSCRIPTION',
}

/** Points balance for a given date */
export interface PointBalance {
  __typename?: 'PointBalance';
  /** Total points this day */
  total: Scalars['Float'];
  /** Calendar day formated as YYYY-MM-DD */
  date: Scalars['Date'];
}

export interface PracticeChat extends Chat, Node {
  __typename?: 'PracticeChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  intro?: Maybe<Dialog>;
  sectionEndQuestion?: Maybe<TextMessageItem>;
  botType: Scalars['String'];
}

export interface PracticeChatIntroArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

export interface PracticeChatSectionEndQuestionArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

export interface Premium {
  __typename?: 'Premium';
  isEnabled: Scalars['Boolean'];
  planType?: Maybe<PremiumPlanType>;
  /** Whether the user is on a trial period */
  isTrial?: Maybe<Scalars['Boolean']>;
  isFamilyLeader?: Maybe<Scalars['Boolean']>;
  endTime?: Maybe<Scalars['DateTime']>;
  paymentProviderName?: Maybe<PaymentProviderName>;
  discount?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  promoCode?: Maybe<PromoCode>;
}

export enum PremiumPlanType {
  Plus = 'Plus',
  PlusTeacher = 'PlusTeacher',
  PlusFamily = 'PlusFamily',
  PlusLifetime = 'PlusLifetime',
}

export interface PremiumPricePlan {
  /** The ID of an object */
  id: Scalars['ID'];
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
}

export interface PremiumPricePlanAppStore extends PremiumPricePlan {
  __typename?: 'PremiumPricePlanAppStore';
  /** The ID of an object */
  id: Scalars['ID'];
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
  appStoreSkuId: Scalars['String'];
  discount?: Maybe<PremiumPricePlanAppStoreDiscount>;
}

export interface PremiumPricePlanAppStoreDiscount {
  __typename?: 'PremiumPricePlanAppStoreDiscount';
  details: PremiumPricePlanAppStoreDiscountOffer;
}

export interface PremiumPricePlanAppStoreDiscountOffer {
  __typename?: 'PremiumPricePlanAppStoreDiscountOffer';
  keyId: Scalars['String'];
  productId: Scalars['String'];
  offerId: Scalars['String'];
  applicationUsername: Scalars['String'];
  nonce: Scalars['String'];
  timestamp: Scalars['DateTime'];
  signature: Scalars['String'];
}

export interface PremiumPricePlanGooglePlay extends PremiumPricePlan {
  __typename?: 'PremiumPricePlanGooglePlay';
  /** The ID of an object */
  id: Scalars['ID'];
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
  skuId: Scalars['String'];
}

export interface PremiumPricePlanStripe {
  __typename?: 'PremiumPricePlanStripe';
  /** The ID of an object */
  id: Scalars['ID'];
  prices: Array<StripePrice>;
  type: PremiumPlanType;
  title: Scalars['String'];
  duration?: Maybe<Scalars['Duration']>;
  trial?: Maybe<StripeTrial>;
}

export interface PremiumPricePlanVoucher extends PremiumPricePlan {
  __typename?: 'PremiumPricePlanVoucher';
  /** The ID of an object */
  id: Scalars['ID'];
  type: PremiumPlanType;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  credits: Scalars['Int'];
  voucherCode: Scalars['String'];
}

/** A chat showing a sample of a premium chat */
export interface PremiumSampleChat extends Chat, Node {
  __typename?: 'PremiumSampleChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

/** A users progress information within courseware */
export interface Progress {
  __typename?: 'Progress';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The user ID the progress is for */
  userId?: Maybe<Scalars['String']>;
  /** The current section progress */
  sectionProgress?: Maybe<SectionProgress>;
  /** The current lesson ID */
  currentLessonId?: Maybe<Scalars['String']>;
  /** The current level ID */
  currentLevelId?: Maybe<Scalars['String']>;
  /** The current level number */
  currentLevelNumber?: Maybe<Scalars['Int']>;
  /** The points that the user has accumulated */
  points?: Maybe<Scalars['Int']>;
  currentLevelOptionInformation: LevelOptionInformation;
  currentCourseProgress: CourseProgress;
  allCourseProgresses: Array<CourseProgress>;
  lessonProgressHistory?: Maybe<LessonProgressConnection>;
}

/** A users progress information within courseware */
export interface ProgressLessonProgressHistoryArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

export interface PromoCode {
  __typename?: 'PromoCode';
  /** The ID of an object */
  id: Scalars['ID'];
  code: Scalars['String'];
  maxRedemptions?: Maybe<Scalars['Int']>;
  redemptionsRemaining?: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  product: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  status: PromoCodeStatus;
}

/** A connection to a list of items. */
export interface PromoCodeConnection {
  __typename?: 'PromoCodeConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PromoCodeEdge>>>;
}

/** An edge in a connection. */
export interface PromoCodeEdge {
  __typename?: 'PromoCodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PromoCode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export enum PromoCodeProduct {
  FiveMinutes = 'FIVE_MINUTES',
  OneHour = 'ONE_HOUR',
  TwelveHours = 'TWELVE_HOURS',
  SevenDays = 'SEVEN_DAYS',
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  SixMonths = 'SIX_MONTHS',
  OneYear = 'ONE_YEAR',
  Lifetime = 'LIFETIME',
  FifteenMinutesTeacher = 'FIFTEEN_MINUTES_TEACHER',
  OneHourTeacher = 'ONE_HOUR_TEACHER',
  SevenDaysTeacher = 'SEVEN_DAYS_TEACHER',
  OneMonthTeacher = 'ONE_MONTH_TEACHER',
  ThreeMonthsTeacher = 'THREE_MONTHS_TEACHER',
  OneYearTeacher = 'ONE_YEAR_TEACHER',
  LifetimeTeacher = 'LIFETIME_TEACHER',
  OneYearTeacher_25Credits = 'ONE_YEAR_TEACHER_25_CREDITS',
  OneHourFamily = 'ONE_HOUR_FAMILY',
  OneHourWithDiscount = 'ONE_HOUR_WITH_DISCOUNT',
  ThreeCreditsBundle = 'THREE_CREDITS_BUNDLE',
}

export enum PromoCodeSourceKind {
  Admin = 'ADMIN',
}

export enum PromoCodeStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Refunded = 'REFUNDED',
}

export type PromoPlan = PromoPlanStripe | PromoPlanStripeFlat;

export interface PromoPlanStripe {
  __typename?: 'PromoPlanStripe';
  premiumPricePlan: PremiumPricePlanStripe;
  promoCode: StripePromoCode;
}

export interface PromoPlanStripeFlat {
  __typename?: 'PromoPlanStripeFlat';
  premiumPricePlan: PremiumPricePlanStripe;
  promoCode: StripePromoCodeFlat;
}

export interface PronunciationReview {
  __typename?: 'PronunciationReview';
  /** The ID of an object */
  id: Scalars['ID'];
  chat?: Maybe<PracticeChat>;
  totalRecordedPronunciations?: Maybe<Scalars['Int']>;
  lastCompletedPracticeSession?: Maybe<StudySession>;
}

export interface PronunciationReviewTotalRecordedPronunciationsArgs {
  since?: Maybe<Scalars['DateTime']>;
}

export interface Query {
  __typename?: 'Query';
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  viewer: Viewer;
  /** Information about whether the client needs to be updated */
  clientUpdate?: Maybe<ClientUpdate>;
  /** List of the available levels */
  levels?: Maybe<Array<Maybe<Level>>>;
  /** List of the available courses */
  courses?: Maybe<Array<Maybe<CoursewareCourse>>>;
  gradeSpeech: GradeSpeechResult;
  /** Returns a list of delights */
  delights: Array<Maybe<Delight>>;
  /** Historic points balance for a user grouped by a time interval (daily) */
  pointBalanceByInterval: Array<Maybe<PointBalance>>;
  /** Get global config information */
  globalConfig?: Maybe<GlobalConfig>;
  /** Returns data for the study reminder feature */
  studyReminder?: Maybe<StudyReminder>;
  admin?: Maybe<AdminQuery>;
  wordPronunciations: Array<Maybe<WordPronunciation>>;
  avatars: Array<Avatar>;
  intl: Intl;
  devPracticeChats?: Maybe<Array<PracticeChat>>;
  gifs?: Maybe<GifConnectionConnection>;
  getExplanation?: Maybe<GetExplanationResponse>;
  getExplanationForVocab?: Maybe<GetExplanationResponse>;
  getHint?: Maybe<GetHintResponse>;
  getMoreExamples?: Maybe<GetMoreExamplesResponse>;
  getSummary?: Maybe<GetSummaryResponse>;
  getRoleplaySuggestions?: Maybe<GetRoleplaySuggestionsResponse>;
  getAIOnboardingIntro?: Maybe<GetAiOnboardingIntroResponse>;
  getAILongOnboardingPactIntro?: Maybe<GetAiOnboardingIntroResponse>;
  getAIOnboardingFollowUp?: Maybe<GetAiOnboardingFollowUpResponse>;
  getAIWelcomeBackMessage?: Maybe<GetAiWelcomeBackMessageResponse>;
  getAICoursewareIntro?: Maybe<GetAiCoursewareIntroResponse>;
}

export interface QueryNodeArgs {
  id: Scalars['ID'];
}

export interface QueryClientUpdateArgs {
  version?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
}

export interface QueryGradeSpeechArgs {
  audio: Base64Upload;
  expected: Scalars['String'];
  language?: StudyLanguage;
}

export interface QueryDelightsArgs {
  kind: DelightKind;
  type?: Maybe<DelightType>;
  amount?: Maybe<Scalars['Int']>;
}

export interface QueryPointBalanceByIntervalArgs {
  fromDate: Scalars['String'];
}

export interface QueryWordPronunciationsArgs {
  words: Array<WordPronunciationArg>;
}

export interface QueryGifsArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  type: GifType;
}

export interface QueryGetExplanationArgs {
  text: Scalars['String'];
  correctChoices?: Maybe<Array<Scalars['String']>>;
  wrongChoices?: Maybe<Array<Scalars['String']>>;
  instruction?: Maybe<Scalars['String']>;
  studyToken?: Maybe<Scalars['String']>;
}

export interface QueryGetExplanationForVocabArgs {
  correctChoices: Array<Scalars['String']>;
  wrongChoices?: Maybe<Array<Scalars['String']>>;
  instruction?: Maybe<Scalars['String']>;
  studyToken?: Maybe<Scalars['String']>;
}

export interface QueryGetHintArgs {
  text: Scalars['String'];
  correctChoices?: Maybe<Array<Scalars['String']>>;
  wrongChoices?: Maybe<Array<Scalars['String']>>;
  instruction?: Maybe<Scalars['String']>;
  studyToken?: Maybe<Scalars['String']>;
}

export interface QueryGetMoreExamplesArgs {
  topic: Scalars['String'];
  previousExamples?: Maybe<Array<Scalars['String']>>;
  sessionToken?: Maybe<Scalars['String']>;
}

export interface QueryGetSummaryArgs {
  sessionToken: Scalars['String'];
}

export interface QueryGetRoleplaySuggestionsArgs {
  roleplaySessionId: Scalars['ID'];
}

export interface QueryGetAiOnboardingFollowUpArgs {
  aiIntro: Scalars['String'];
  userResponse: Scalars['String'];
}

export interface QueryGetAiWelcomeBackMessageArgs {
  language: StudyLanguage;
}

export interface QueryGetAiCoursewareIntroArgs {
  aiIntro?: Maybe<Scalars['String']>;
  sectionId: Scalars['ID'];
  isFirstSectionOfSession?: Maybe<Scalars['Boolean']>;
}

export interface ReadingMessageItem {
  __typename?: 'ReadingMessageItem';
  title?: Maybe<CardMessageItemTitle>;
  items: Array<CardMessageItemBodyItem>;
  image?: Maybe<CardMessageItemImage>;
  subtitle?: Maybe<CardMessageItemSubtitle>;
}

export interface ReadingReviewContentItem {
  __typename?: 'ReadingReviewContentItem';
  title: CardReviewContentItemTitle;
  subtitle?: Maybe<CardReviewContentItemSubtitle>;
  items: Array<CardReviewContentItemBodyItem>;
  image?: Maybe<CardReviewContentItemImage>;
}

export interface RecordCancellationReasonInput {
  cancellationReason: CancellationReason;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RecordCancellationReasonPayload {
  __typename?: 'RecordCancellationReasonPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RecordStudyResponseInput {
  studyToken: Scalars['String'];
  studySessionId: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
  userAssetId?: Maybe<Scalars['ID']>;
  selectedChoiceIdentifier?: Maybe<Scalars['String']>;
  /** The attempt number of the learner answering this quetion, 0-indexed */
  attemptNum?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RecordStudyResponsePayload {
  __typename?: 'RecordStudyResponsePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemPromoCodeInput {
  /** promo code to redeem */
  code: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemPromoCodePayload {
  __typename?: 'RedeemPromoCodePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemVoucherInput {
  /** voucher code to redeem */
  code: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RedeemVoucherPayload {
  __typename?: 'RedeemVoucherPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RefreshDailyPlanInput {
  studyLanguage: StudyLanguage;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RefreshDailyPlanPayload {
  __typename?: 'RefreshDailyPlanPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RegisterAccountAnonymousInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface RegisterAccountAnonymousPayload {
  __typename?: 'RegisterAccountAnonymousPayload';
  viewer: Viewer;
  token: Scalars['String'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ReviewAnnotation {
  __typename?: 'ReviewAnnotation';
  content: Scalars['String'];
}

export type ReviewContentItem =
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | TextCardReviewContentItem
  | CardCarouselReviewContentItem
  | ReadingReviewContentItem
  | TableReviewContentItem
  | ImageAudioCardReviewContentItem;

export type ReviewEncounter =
  | ReviewSpeakingEncounter
  | ReviewMultipleChoiceTextEncounter;

export interface ReviewEncounterGroup {
  __typename?: 'ReviewEncounterGroup';
  context: Array<ReviewContentItem>;
  encounters: Array<ReviewEncounter>;
}

export interface ReviewExample {
  __typename?: 'ReviewExample';
  exampleTitle?: Maybe<ReviewExampleTitle>;
  items: Array<ReviewContentItem>;
}

export interface ReviewExampleTitle {
  __typename?: 'ReviewExampleTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface ReviewLesson extends Node {
  __typename?: 'ReviewLesson';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  notes: Array<ReviewNote>;
  notesLength: Scalars['Int'];
  notebook: ReviewNotebook;
}

export interface ReviewMultipleChoiceTextEncounter {
  __typename?: 'ReviewMultipleChoiceTextEncounter';
  /** The ID of an object */
  id: Scalars['ID'];
  question: ReviewMultipleChoiceTextQuestion;
  isCorrect?: Maybe<Scalars['Boolean']>;
  isAnswered: Scalars['Boolean'];
  responses: Array<ReviewMultipleChoiceTextEncounterResponse>;
}

export interface ReviewMultipleChoiceTextEncounterResponse {
  __typename?: 'ReviewMultipleChoiceTextEncounterResponse';
  isCorrect: Scalars['Boolean'];
  choice?: Maybe<ReviewMultipleChoiceTextQuestionChoice>;
}

export interface ReviewMultipleChoiceTextQuestion {
  __typename?: 'ReviewMultipleChoiceTextQuestion';
  /** The ID of an object */
  id: Scalars['ID'];
  items: Array<ReviewContentItem>;
  choices: Array<ReviewMultipleChoiceTextQuestionChoice>;
  correctChoiceIndices: Array<Scalars['Int']>;
}

export interface ReviewMultipleChoiceTextQuestionChoice {
  __typename?: 'ReviewMultipleChoiceTextQuestionChoice';
  text: Scalars['String'];
}

export interface ReviewNote extends Node {
  __typename?: 'ReviewNote';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  annotation?: Maybe<ReviewAnnotation>;
  stats?: Maybe<ReviewNoteStats>;
  contents: Array<ReviewNoteContentItem>;
  encounters: Array<ReviewNoteEncounterBodyItem>;
  description: Scalars['String'];
  lesson: ReviewLesson;
  practiceStudySection?: Maybe<ReviewNotePracticeStudySection>;
  language: StudyLanguage;
}

export interface ReviewNotebook extends Node {
  __typename?: 'ReviewNotebook';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  lessons: Array<ReviewLesson>;
  lastModified: Scalars['DateTime'];
  coverNumber: Scalars['Int'];
  coverLabel: Scalars['String'];
  difficulty?: Maybe<CourseDifficulty>;
}

export type ReviewNoteContentItem =
  | ReviewTopic
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | TextCardReviewContentItem
  | CardCarouselReviewContentItem
  | ReadingReviewContentItem
  | TableReviewContentItem
  | ImageAudioCardReviewContentItem;

export type ReviewNoteEncounterBodyItem =
  | ReviewSpeakingEncounter
  | ReviewMultipleChoiceTextEncounter
  | ReviewEncounterGroup;

export interface ReviewNotePracticeStudySection extends Node, StudySection {
  __typename?: 'ReviewNotePracticeStudySection';
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  color?: Maybe<Scalars['HexColorCode']>;
}

export interface ReviewNoteStats {
  __typename?: 'ReviewNoteStats';
  bestScore: ReviewNoteStatsScore;
  previousScore?: Maybe<ReviewNoteStatsScore>;
}

export interface ReviewNoteStatsScore {
  __typename?: 'ReviewNoteStatsScore';
  score: Scalars['Int'];
  time: Scalars['DateTime'];
}

export interface ReviewSpeakingEncounter {
  __typename?: 'ReviewSpeakingEncounter';
  /** The ID of an object */
  id: Scalars['ID'];
  question: ReviewSpeakingQuestion;
  isCorrect?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  isAnswered: Scalars['Boolean'];
  responses: Array<ReviewSpeakingEncounterResponse>;
}

export interface ReviewSpeakingEncounterResponse {
  __typename?: 'ReviewSpeakingEncounterResponse';
  isCorrect: Scalars['Boolean'];
  score?: Maybe<Scalars['Float']>;
  audio?: Maybe<ReviewSpeakingEncounterResponseAudio>;
}

export interface ReviewSpeakingEncounterResponseAudio {
  __typename?: 'ReviewSpeakingEncounterResponseAudio';
  url: Scalars['String'];
  duration: Scalars['Float'];
}

export interface ReviewSpeakingQuestion {
  __typename?: 'ReviewSpeakingQuestion';
  /** The ID of an object */
  id: Scalars['ID'];
  items: Array<ReviewContentItem>;
  content: ReviewSpeakingQuestionContent;
}

export interface ReviewSpeakingQuestionContent {
  __typename?: 'ReviewSpeakingQuestionContent';
  text: Scalars['String'];
}

export interface ReviewTopic {
  __typename?: 'ReviewTopic';
  topicTitle?: Maybe<ReviewTopicTitle>;
  body: Array<ReviewTopicBodyItem>;
}

export type ReviewTopicBodyItem =
  | ReviewExample
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | TextCardReviewContentItem
  | CardCarouselReviewContentItem
  | ReadingReviewContentItem
  | TableReviewContentItem
  | ImageAudioCardReviewContentItem;

export interface ReviewTopicTitle {
  __typename?: 'ReviewTopicTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

/** A roleplay */
export interface Roleplay extends Node {
  __typename?: 'Roleplay';
  /** The ID of an object */
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  imageBlurhash?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  userRole: Scalars['String'];
  userGoal: Scalars['String'];
  lastCompletedSession?: Maybe<RoleplaySession>;
  difficulty?: Maybe<SmartCourseDifficulty>;
  partnerName?: Maybe<Scalars['String']>;
  partnerImageUrl?: Maybe<Scalars['String']>;
  partnerGender?: Maybe<Scalars['String']>;
  partnerRole?: Maybe<Scalars['String']>;
}

export interface RoleplayCollection extends Node {
  __typename?: 'RoleplayCollection';
  /** The ID of an object */
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  roleplays: RoleplayConnection;
  displayType?: Maybe<RoleplayCollectionDisplayType>;
}

export interface RoleplayCollectionRoleplaysArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

export enum RoleplayCollectionDisplayType {
  Square = 'SQUARE',
  Wide = 'WIDE',
}

/** A connection to a list of items. */
export interface RoleplayConnection {
  __typename?: 'RoleplayConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoleplayEdge>>>;
}

/** An edge in a connection. */
export interface RoleplayEdge {
  __typename?: 'RoleplayEdge';
  /** The item at the end of the edge */
  node?: Maybe<Roleplay>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export type RoleplayLibraryItem =
  | RoleplayCollection
  | RoleplayPlaylistCollection;

/** A roleplay playlist */
export interface RoleplayPlaylist extends Node {
  __typename?: 'RoleplayPlaylist';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  roleplays: RoleplayConnection;
  cover?: Maybe<RoleplayPlaylistCover>;
}

/** A roleplay playlist */
export interface RoleplayPlaylistRoleplaysArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

export interface RoleplayPlaylistCollection extends Node {
  __typename?: 'RoleplayPlaylistCollection';
  /** The ID of an object */
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  playlists: RoleplayPlaylistConnection;
}

export interface RoleplayPlaylistCollectionPlaylistsArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

/** A connection to a list of items. */
export interface RoleplayPlaylistConnection {
  __typename?: 'RoleplayPlaylistConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoleplayPlaylistEdge>>>;
}

export interface RoleplayPlaylistCover {
  __typename?: 'RoleplayPlaylistCover';
  url: Scalars['String'];
  color: Scalars['String'];
  title: Scalars['String'];
}

/** An edge in a connection. */
export interface RoleplayPlaylistEdge {
  __typename?: 'RoleplayPlaylistEdge';
  /** The item at the end of the edge */
  node?: Maybe<RoleplayPlaylist>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

/** A roleplay sessions */
export interface RoleplaySession {
  __typename?: 'RoleplaySession';
  /** The ID of an object */
  id: Scalars['ID'];
  roleplay: Roleplay;
  grade?: Maybe<RoleplaySessionGrade>;
  textToSpeechProvider?: Maybe<TextToSpeechProvider>;
  sessionType?: Maybe<RoleplaySessionType>;
  completed?: Maybe<Scalars['Date']>;
}

export interface RoleplaySessionGrade {
  __typename?: 'RoleplaySessionGrade';
  pronunciation?: Maybe<Scalars['String']>;
  fluency?: Maybe<Scalars['String']>;
  lengthOfResponse?: Maybe<Scalars['String']>;
  grammarMistakes?: Maybe<Scalars['Int']>;
  goalAchieved?: Maybe<Scalars['String']>;
  qualitativeFeedback?: Maybe<Scalars['String']>;
  overallGrade?: Maybe<Scalars['Float']>;
  state?: Maybe<GradeState>;
  points?: Maybe<Scalars['Int']>;
  pronunciationScore?: Maybe<Scalars['Float']>;
  fluencyScore?: Maybe<Scalars['Float']>;
  lengthOfResponseScore?: Maybe<Scalars['Float']>;
  goalAchievedScore?: Maybe<Scalars['Float']>;
}

export enum RoleplaySessionType {
  VoiceCall = 'VOICE_CALL',
  Chat = 'CHAT',
  Vapi = 'VAPI',
}

export interface RoleplaySuggestion {
  __typename?: 'RoleplaySuggestion';
  /** The text of the suggestion */
  text?: Maybe<Scalars['String']>;
  /** The localized text of the suggestion */
  localizedText?: Maybe<Scalars['String']>;
}

/** A users progression through the lesson */
export interface SectionProgress {
  __typename?: 'SectionProgress';
  /** The ID of an object */
  id: Scalars['ID'];
  section: CoursewareStudySection;
  /** The ID of the section */
  sectionId?: Maybe<Scalars['String']>;
  /** The title of the section */
  sectionTitle?: Maybe<Scalars['String']>;
  /** The type of the section */
  sectionType?: Maybe<SectionType>;
  /** When the section was started */
  startDate?: Maybe<Scalars['DateTime']>;
  /** When the section was completed */
  completedDate?: Maybe<Scalars['DateTime']>;
  /** The current section ID */
  isCompleted: Scalars['Boolean'];
  /** Determines if the section is currently locked */
  isLocked: Scalars['Boolean'];
  /** The ID of the conversation formation where the section ends */
  formationEndId?: Maybe<Scalars['String']>;
  /** The points that the user has earnt for this section */
  points?: Maybe<Scalars['Int']>;
  reviewNote?: Maybe<ReviewNote>;
  /** The location of this lesson in the level lessons */
  lessonProgress: LessonProgress;
}

/** Determines the type of a courseware lesson section */
export enum SectionType {
  /** A grammar section */
  Grammar = 'grammar',
  /** A speaking section */
  Speaking = 'speaking',
  /** A listening section */
  Listening = 'listening',
  /** A vocabulary section */
  Vocabulary = 'vocabulary',
  /** A reading section */
  Reading = 'reading',
  /** A expressions section */
  Expressions = 'expressions',
  /** A quiz section */
  Quiz = 'quiz',
  /** A skills section */
  Skills = 'skills',
  /** A finalTask section */
  FinalTask = 'finalTask',
}

export interface SectionTypeStat {
  __typename?: 'SectionTypeStat';
  sectionType: Scalars['String'];
  /** score for this section type, between 0 and 1 */
  score: Scalars['Float'];
}

export interface ShowcaseLesson {
  __typename?: 'ShowcaseLesson';
  /** The ID of an object */
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  analyticsName?: Maybe<Scalars['String']>;
  language: StudyLanguage;
  sections: Array<ShowcaseSection>;
  sectionsLength: Scalars['Int'];
  intro?: Maybe<Dialog>;
  outro?: Maybe<Dialog>;
  botType: Scalars['String'];
}

export interface ShowcaseLessonIntroArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

export interface ShowcaseRoleplaySection {
  __typename?: 'ShowcaseRoleplaySection';
  /** The ID of an object */
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  /** The number of the lesson within its course */
  numberInLesson: Scalars['Int'];
  sectionType?: Maybe<SmartCourseSectionType>;
  roleplay: Roleplay;
  dialog: Dialog;
  outro?: Maybe<Dialog>;
}

export type ShowcaseSection = ShowcaseStudySection | ShowcaseRoleplaySection;

export interface ShowcaseSectionProgress {
  __typename?: 'ShowcaseSectionProgress';
  isCompleted: Scalars['Boolean'];
}

export interface ShowcaseStudySection extends StudySection {
  __typename?: 'ShowcaseStudySection';
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  color?: Maybe<Scalars['HexColorCode']>;
  /** The number of the lesson within its course */
  numberInLesson: Scalars['Int'];
  sectionType?: Maybe<SmartCourseSectionType>;
  outro?: Maybe<Dialog>;
  progress?: Maybe<ShowcaseSectionProgress>;
}

export interface ShowcaseStudySectionProgressArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourse extends Node {
  __typename?: 'SmartCourse';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  analyticsName?: Maybe<Scalars['String']>;
  language: StudyLanguage;
  lessons: Array<SmartCourseLesson>;
  difficulty: SmartCourseDifficulty;
  topics: Array<SmartCourseTopic>;
  lessonsLength: Scalars['Int'];
  sectionsLength: Scalars['Int'];
  cover?: Maybe<SmartCourseCover>;
  progress?: Maybe<SmartCourseProgress>;
  enrollment?: Maybe<SmartCourseEnrollment>;
  access?: Maybe<SmartCourseAccess>;
  isGifted?: Maybe<Scalars['Boolean']>;
  /** A deduplicated list of all vocab locations belonging to lessons in this SmartCourse */
  vocabLocations?: Maybe<Array<VocabLocation>>;
}

export interface SmartCourseProgressArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourseEnrollmentArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourseAccessArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourseIsGiftedArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourseAccess {
  __typename?: 'SmartCourseAccess';
  policy: SmartCourseAccessPolicy;
}

export enum SmartCourseAccessPolicy {
  Unrestricted = 'UNRESTRICTED',
  Restricted = 'RESTRICTED',
  None = 'NONE',
}

export interface SmartCourseCollection extends Node {
  __typename?: 'SmartCourseCollection';
  /** The ID of an object */
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  courses: SmartCourseConnection;
}

export interface SmartCourseCollectionCoursesArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<SmartCourseDifficulty>;
  orderBy?: Maybe<SmartCourseCollectionOrderBy>;
}

export enum SmartCourseCollectionOrderBy {
  Default = 'DEFAULT',
  DifficultyAsc = 'DIFFICULTY_ASC',
}

/** A connection to a list of items. */
export interface SmartCourseConnection {
  __typename?: 'SmartCourseConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SmartCourseEdge>>>;
}

export interface SmartCourseCover {
  __typename?: 'SmartCourseCover';
  foregroundColor: Scalars['HexColorCode'];
  backgroundColor: Scalars['HexColorCode'];
  image?: Maybe<Image>;
  title: Scalars['String'];
}

export enum SmartCourseDifficulty {
  None = 'NONE',
  Basic = 'BASIC',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

/** An edge in a connection. */
export interface SmartCourseEdge {
  __typename?: 'SmartCourseEdge';
  /** The item at the end of the edge */
  node?: Maybe<SmartCourse>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface SmartCourseEnrollInitialCourseInput {
  language?: Maybe<StudyLanguage>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseEnrollInitialCoursePayload {
  __typename?: 'SmartCourseEnrollInitialCoursePayload';
  viewer: Viewer;
  enrolledCourse?: Maybe<SmartCourse>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseEnrollInput {
  smartCourseId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseEnrollment {
  __typename?: 'SmartCourseEnrollment';
  isEnrolled: Scalars['Boolean'];
}

export interface SmartCourseEnrollPayload {
  __typename?: 'SmartCourseEnrollPayload';
  smartCourse: SmartCourse;
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseLesson extends Node {
  __typename?: 'SmartCourseLesson';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  analyticsName: Scalars['String'];
  course: SmartCourse;
  sections: Array<SmartCourseSection>;
  sectionsLength: Scalars['Int'];
  /** the bot is only available for authenticated users, it will be null if requested without auth */
  bot?: Maybe<SmartCourseLessonBot>;
  /** The number of the lesson within its course */
  numberInCourse: Scalars['Int'];
  reviewLesson?: Maybe<ReviewLesson>;
  progress?: Maybe<SmartCourseLessonProgress>;
  vocabSuggestions?: Maybe<Array<Scalars['String']>>;
  supplementalVocabSuggestions?: Maybe<Array<Scalars['String']>>;
  vocabLocations?: Maybe<Array<VocabLocation>>;
  roleplays?: Maybe<Array<Roleplay>>;
}

export interface SmartCourseLessonReviewLessonArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourseLessonProgressArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourseLessonBot {
  __typename?: 'SmartCourseLessonBot';
  /** The ID of an object */
  id: Scalars['ID'];
  intro?: Maybe<Dialog>;
  paywallUnlockTime?: Maybe<Scalars['DateTime']>;
}

export interface SmartCourseLessonBotIntroArgs {
  isFirstRun?: Maybe<Scalars['Boolean']>;
}

export interface SmartCourseLessonProgress {
  __typename?: 'SmartCourseLessonProgress';
  isCompleted: Scalars['Boolean'];
  completedSectionsCount: Scalars['Int'];
}

export interface SmartCourseMigrateInput {
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseMigratePayload {
  __typename?: 'SmartCourseMigratePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseProgress {
  __typename?: 'SmartCourseProgress';
  isCompleted: Scalars['Boolean'];
  completedLessonsCount: Scalars['Int'];
  completedSectionsCount: Scalars['Int'];
}

export type SmartCourseReviewSummaryContentItem =
  | ReviewTopic
  | TextReviewContentItem
  | GifReviewContentItem
  | AudioReviewContentItem
  | AudioCarouselReviewContentItem
  | WebLinkReviewContentItem
  | VideoReviewContentItem
  | ImageReviewContentItem
  | CardReviewContentItem
  | TextCardReviewContentItem
  | CardCarouselReviewContentItem
  | ReadingReviewContentItem
  | TableReviewContentItem
  | ImageAudioCardReviewContentItem;

export interface SmartCourseSection extends Node {
  __typename?: 'SmartCourseSection';
  /** The ID of an object */
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  lesson: SmartCourseLesson;
  /** The number of the lesson within its course */
  numberInLesson: Scalars['Int'];
  content: SmartCourseSectionContent;
  sectionType?: Maybe<SmartCourseSectionType>;
  progress?: Maybe<SmartCourseSectionProgress>;
  reviewSummaryContents: Array<SmartCourseReviewSummaryContentItem>;
  vocabSuggestions?: Maybe<Array<Scalars['String']>>;
}

export interface SmartCourseSectionProgressArgs {
  userId?: Maybe<Scalars['ID']>;
}

export interface SmartCourseSectionContent extends StudySection {
  __typename?: 'SmartCourseSectionContent';
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  color?: Maybe<Scalars['HexColorCode']>;
  theme?: Maybe<SmartCourseSectionTheme>;
  intro?: Maybe<Dialog>;
}

export interface SmartCourseSectionProgress {
  __typename?: 'SmartCourseSectionProgress';
  isCompleted: Scalars['Boolean'];
}

export interface SmartCourseSectionTheme {
  __typename?: 'SmartCourseSectionTheme';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
  intro?: Maybe<SmartCourseSectionThemeIntro>;
}

export interface SmartCourseSectionThemeIntro {
  __typename?: 'SmartCourseSectionThemeIntro';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export enum SmartCourseSectionType {
  Grammar = 'Grammar',
  Speaking = 'Speaking',
  Listening = 'Listening',
  Vocabulary = 'Vocabulary',
  Reading = 'Reading',
  Expressions = 'Expressions',
  Quiz = 'Quiz',
  Skills = 'Skills',
  FinalTask = 'FinalTask',
}

export enum SmartCourseSectionTypePreference {
  Pronunciation = 'PRONUNCIATION',
  Grammar = 'GRAMMAR',
  Vocabulary = 'VOCABULARY',
  Listening = 'LISTENING',
  Reading = 'READING',
}

export interface SmartCourseStudySessionSectionContent
  extends StudySessionSectionContent {
  __typename?: 'SmartCourseStudySessionSectionContent';
  type: Scalars['String'];
  section?: Maybe<SmartCourseSection>;
}

export interface SmartCourseSubmitPreferencesInput {
  level?: Maybe<UserLevelPreference>;
  topic?: Maybe<SmartCourseTopicPreference>;
  sectionType?: Maybe<SmartCourseSectionTypePreference>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseSubmitPreferencesPayload {
  __typename?: 'SmartCourseSubmitPreferencesPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum SmartCourseTopic {
  GeneralConversation = 'GENERAL_CONVERSATION',
  Travel = 'TRAVEL',
  Hobbies = 'HOBBIES',
  DailyLife = 'DAILY_LIFE',
  Business = 'BUSINESS',
  School = 'SCHOOL',
  Lifestyle = 'LIFESTYLE',
  ArtAndCulture = 'ART_AND_CULTURE',
}

export enum SmartCourseTopicPreference {
  Business = 'BUSINESS',
  Travel = 'TRAVEL',
  School = 'SCHOOL',
  Hobbies = 'HOBBIES',
  DailyLife = 'DAILY_LIFE',
  GeneralConversation = 'GENERAL_CONVERSATION',
  Other = 'OTHER',
}

export interface SmartCourseUnenrollInput {
  smartCourseId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseUnenrollPayload {
  __typename?: 'SmartCourseUnenrollPayload';
  viewer: Viewer;
  smartCourse: SmartCourse;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SmartCourseUserPreferences {
  __typename?: 'SmartCourseUserPreferences';
  level: UserLevelPreference;
  topics: Array<SmartCourseTopicPreference>;
  sectionTypes: Array<SmartCourseSectionTypePreference>;
}

export interface SmartReview {
  __typename?: 'SmartReview';
  notes?: Maybe<NotesReview>;
  vocab?: Maybe<VocabularyReview>;
  pronunciation?: Maybe<PronunciationReview>;
  coursewareSection?: Maybe<CoursewareSectionReview>;
  studySet?: Maybe<StudySetReview>;
}

export enum SocialAuthProvider {
  Apple = 'APPLE',
  Google = 'GOOGLE',
}

export interface StartStudySessionInput {
  studySectionToken: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface StartStudySessionPayload {
  __typename?: 'StartStudySessionPayload';
  viewer: Viewer;
  studySession?: Maybe<StudySession>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface Story extends Node {
  __typename?: 'Story';
  /** The ID of an object */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  posts: Array<StoryPost>;
}

export interface StoryPost {
  __typename?: 'StoryPost';
  /** The ID of an object */
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['HexColorCode']>;
  created: Scalars['DateTime'];
  activity?: Maybe<StoryPostActivity>;
}

export interface StoryPostActivity {
  __typename?: 'StoryPostActivity';
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
}

export interface StripeCheckout {
  __typename?: 'StripeCheckout';
  premiumPricePlans: Array<PremiumPricePlanStripe>;
  premiumPricePlanByDuration?: Maybe<PremiumPricePlanStripe>;
  premiumPricePlanLifetime?: Maybe<PremiumPricePlanStripe>;
  recommendedCurrency: SupportedCurrency;
  promoPlans: Array<Maybe<PromoPlan>>;
}

export interface StripeCheckoutPremiumPricePlansArgs {
  duration?: Maybe<Scalars['Duration']>;
}

export interface StripeCheckoutPremiumPricePlanByDurationArgs {
  duration: Scalars['Duration'];
}

export interface StripeCheckoutPromoPlansArgs {
  planType?: Maybe<PremiumPlanType>;
}

export interface StripeCheckoutSession {
  __typename?: 'StripeCheckoutSession';
  identifier: Scalars['String'];
}

/** StripePrice contains the currencies and price that are available for a premium price plan */
export interface StripePrice {
  __typename?: 'StripePrice';
  currency: SupportedCurrency;
  price: Scalars['Float'];
}

export interface StripePromoCode {
  __typename?: 'StripePromoCode';
  code: Scalars['String'];
  /** The ID of an object */
  id: Scalars['ID'];
  percentageOff: Scalars['Float'];
}

export interface StripePromoCodeFlat {
  __typename?: 'StripePromoCodeFlat';
  /** The ID of an object */
  id: Scalars['ID'];
  code: Scalars['String'];
  amountOff: Scalars['Float'];
  currency: SupportedCurrency;
}

export interface StripeTrial {
  __typename?: 'StripeTrial';
  duration: Scalars['Duration'];
}

export enum StudyLanguage {
  En = 'EN',
  De = 'DE',
  Es = 'ES',
  Fr = 'FR',
  It = 'IT',
}

/** Study reminder */
export interface StudyReminder {
  __typename?: 'StudyReminder';
  /** Messages for the study reminders */
  messages: StudyReminderMessages;
}

/** Recurring notifications to remind the user to study */
export interface StudyReminderConfig {
  __typename?: 'StudyReminderConfig';
  /** Version of the reminder messages */
  currentMessagesVersion: Scalars['Int'];
}

/** Messages to be used for the study reminders */
export interface StudyReminderMessages {
  __typename?: 'StudyReminderMessages';
  /** Messages with a nice tone */
  nice: Array<Scalars['String']>;
  /** Messages with a nasty tone */
  nasty: Array<Scalars['String']>;
}

export interface StudySection {
  /** The ID of an object */
  id: Scalars['ID'];
  dialog: Dialog;
  title?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  color?: Maybe<Scalars['HexColorCode']>;
}

export interface StudySession extends Node {
  __typename?: 'StudySession';
  /** The ID of an object */
  id: Scalars['ID'];
  pointsEarned?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
  completionTime?: Maybe<Scalars['DateTime']>;
  section: StudySessionSectionContent;
}

/** A connection to a list of items. */
export interface StudySessionConnection {
  __typename?: 'StudySessionConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StudySessionEdge>>>;
}

/** An edge in a connection. */
export interface StudySessionEdge {
  __typename?: 'StudySessionEdge';
  /** The item at the end of the edge */
  node?: Maybe<StudySession>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
}

export interface StudySessionSectionContent {
  type: Scalars['String'];
}

export interface StudySet extends Node {
  __typename?: 'StudySet';
  /** The ID of an object */
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  title: StudySetTitle;
  sections: Array<StudySetSection>;
}

/** A chat for studying study sets */
export interface StudySetChat extends Chat, Node {
  __typename?: 'StudySetChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
  studySet?: Maybe<StudySet>;
  hasEnoughData: Scalars['Boolean'];
  botType: Scalars['String'];
}

export interface StudySetReview {
  __typename?: 'StudySetReview';
  /** The ID of an object */
  id: Scalars['ID'];
  chat?: Maybe<StudySetChat>;
  hasEnoughData?: Maybe<Scalars['Boolean']>;
}

export interface StudySetSection {
  __typename?: 'StudySetSection';
  title: StudySetSectionTitle;
  isCompleted: Scalars['Boolean'];
  sectionType: StudySetSectionType;
  role: StudySetSectionRole;
  icon?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['HexColorCode']>;
}

export enum StudySetSectionRole {
  Warmup = 'WARMUP',
  Focus = 'FOCUS',
  Review = 'REVIEW',
}

export interface StudySetSectionTitle {
  __typename?: 'StudySetSectionTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export enum StudySetSectionType {
  Pronunciation = 'PRONUNCIATION',
  Vocab = 'VOCAB',
  Listening = 'LISTENING',
  Review = 'REVIEW',
}

export interface StudySetTitle {
  __typename?: 'StudySetTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface SubmitTeacherQuestionInput {
  question: Scalars['String'];
  studySessionId?: Maybe<Scalars['ID']>;
  dialogStatementIdentifier?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface SubmitTeacherQuestionPayload {
  __typename?: 'SubmitTeacherQuestionPayload';
  askATeacherTicket?: Maybe<AskATeacherTicket>;
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum SupportedCurrency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bgn = 'BGN',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cve = 'CVE',
  Czk = 'CZK',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Eur = 'EUR',
  Fjd = 'FJD',
  Gbp = 'GBP',
  Gel = 'GEL',
  Gmd = 'GMD',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Krw = 'KRW',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Srd = 'SRD',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Usd = 'USD',
  Ugx = 'UGX',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vnd = 'VND',
  Vuv = 'VUV',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xof = 'XOF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
}

export interface SupportedLocalizedContentLanguage {
  __typename?: 'SupportedLocalizedContentLanguage';
  tag: Scalars['String'];
  name: Scalars['String'];
}

export enum SupportedLocalizedContentLanguageEnum {
  En = 'en',
  De = 'de',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Pt = 'pt',
  Ru = 'ru',
  Th = 'th',
  Tr = 'tr',
  Vi = 'vi',
  ZhHans = 'zhHans',
  ZhHant = 'zhHant',
}

export interface TableMessageItem {
  __typename?: 'TableMessageItem';
  headers: Array<TableMessageItemCell>;
  rows: Array<TableMessageItemRow>;
}

export interface TableMessageItemCell {
  __typename?: 'TableMessageItemCell';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface TableMessageItemRow {
  __typename?: 'TableMessageItemRow';
  header?: Maybe<TableMessageItemCell>;
  cells: Array<TableMessageItemCell>;
}

export interface TableReviewContentItem {
  __typename?: 'TableReviewContentItem';
  headers: Array<TableReviewContentItemCell>;
  rows: Array<TableReviewContentItemRow>;
}

export interface TableReviewContentItemCell {
  __typename?: 'TableReviewContentItemCell';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface TableReviewContentItemRow {
  __typename?: 'TableReviewContentItemRow';
  header?: Maybe<TableReviewContentItemCell>;
  cells: Array<TableReviewContentItemCell>;
}

export interface TeacherChat extends Chat, Node {
  __typename?: 'TeacherChat';
  /** The ID of an object */
  id: Scalars['ID'];
  /** The type of chat */
  type: ChatType;
  /** The url of the avatar for the chat */
  avatar: Scalars['String'];
  /** The name of the chat */
  name: Scalars['String'];
  /** Extra information for the chat, usually the last message */
  subTitle: Scalars['String'];
  /** The number of messages that have not been seen by the user */
  unreadMessages: Scalars['Int'];
  /** Indicates whether the chat is a premium feature */
  isPremium: Scalars['Boolean'];
  /** Indicates the level of the premium plan (if applicable) */
  premiumType?: Maybe<PremiumPlanType>;
  /** ISO 8601 timestamp of the last time the chat was modified. Currently defaults to EFs creation year */
  lastModified: Scalars['DateTime'];
}

export interface TextCardMessageItem {
  __typename?: 'TextCardMessageItem';
  items: Array<CardMessageItemBodyText>;
}

export interface TextCardReviewContentItem {
  __typename?: 'TextCardReviewContentItem';
  items: Array<CardReviewContentItemBodyText>;
}

export interface TextMessageItem {
  __typename?: 'TextMessageItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface TextReviewContentItem {
  __typename?: 'TextReviewContentItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface TextToSpeechAudio {
  __typename?: 'TextToSpeechAudio';
  base64: Scalars['String'];
  transcript?: Maybe<Scalars['String']>;
}

export interface TextToSpeechInput {
  text: Scalars['String'];
  language: StudyLanguage;
  gender?: Maybe<Scalars['String']>;
  /** A string to ensure consistent voice choice, e.g. a roleplaySessionId */
  voiceSeed?: Maybe<Scalars['String']>;
  provider?: Maybe<TextToSpeechProvider>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface TextToSpeechPayload {
  __typename?: 'TextToSpeechPayload';
  audio?: Maybe<TextToSpeechAudio>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export enum TextToSpeechProvider {
  Google = 'GOOGLE',
  ElevenLabs = 'ELEVEN_LABS',
  OpenAi = 'OPEN_AI',
  Deepgram = 'DEEPGRAM',
  Azure = 'AZURE',
}

export interface TranscribeUserSpeechInput {
  /** Audio of speech to transcribe */
  audio: Base64Upload;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface TranscribeUserSpeechPayload {
  __typename?: 'TranscribeUserSpeechPayload';
  userAsset?: Maybe<AudioUserAsset>;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** translations enhancements added to a sense */
export interface TranslationsDictionaryEnhancement
  extends DictionaryEnhancementInterface {
  __typename?: 'TranslationsDictionaryEnhancement';
  /** The ID of an object */
  id: Scalars['ID'];
  source: DictionarySenseEnhancementSource;
  translations: Array<TranslationsMapping>;
}

export interface TranslationsMapping {
  __typename?: 'TranslationsMapping';
  language: DictionaryLanguage;
  translations: Array<Scalars['String']>;
}

export interface TranslationsMappingInput {
  language: DictionaryLanguage;
  translations: Array<Scalars['String']>;
}

export interface UpdateAccountInfoInput {
  name?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  region?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  avatarId?: Maybe<Scalars['ID']>;
  localizedContentLanguage?: Maybe<Scalars['String']>;
  ageRange?: Maybe<AgeRange>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateAccountInfoPayload {
  __typename?: 'UpdateAccountInfoPayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateAnnotationForReviewNoteInput {
  reviewNoteId: Scalars['ID'];
  annotation: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface UpdateAnnotationForReviewNotePayload {
  __typename?: 'UpdateAnnotationForReviewNotePayload';
  reviewNote?: Maybe<ReviewNote>;
  clientMutationId?: Maybe<Scalars['String']>;
}

/** The type of the update available for the user */
export enum UpdateType {
  /** The update must be performed to use the app */
  Forced = 'forced',
  /** The update is not required to continue to use the app */
  Optional = 'optional',
  /** There is no update available to the client */
  None = 'none',
}

export interface User extends Node {
  __typename?: 'User';
  /** The ID of an object */
  id: Scalars['ID'];
  legacyId: Scalars['String'];
  trackingId: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Avatar>;
  isIdentified?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  localizedContentLanguage?: Maybe<SupportedLocalizedContentLanguage>;
  hasCompletedSmartCourseMigration?: Maybe<Scalars['Boolean']>;
  smartCoursePreferences?: Maybe<SmartCourseUserPreferences>;
  hasCompletedOnboarding?: Maybe<Scalars['Boolean']>;
  hasSubmittedSmartCoursePreferences?: Maybe<Scalars['Boolean']>;
  hasFirstEnrollment?: Maybe<Scalars['Boolean']>;
  authSource?: Maybe<Array<Maybe<AuthSource>>>;
  premium?: Maybe<Premium>;
  credits?: Maybe<Scalars['Int']>;
  progress?: Maybe<Progress>;
  points?: Maybe<Scalars['Int']>;
  classBookingHistory?: Maybe<ClassBookingConnection>;
  completedStudySessions?: Maybe<StudySessionConnection>;
  sectionTypeStats?: Maybe<Array<SectionTypeStat>>;
  isForgotten: Scalars['Boolean'];
  allBucketAssignments?: Maybe<Array<BucketAssignment>>;
  lockedInBucketAssignments?: Maybe<Array<BucketAssignment>>;
  hasUsedRoleplay?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<UserTag>>;
  hasCompletedAIOnboarding?: Maybe<Scalars['Boolean']>;
  ageRange?: Maybe<AgeRange>;
}

export interface UserHasSubmittedSmartCoursePreferencesArgs {
  language?: Maybe<StudyLanguage>;
}

export interface UserHasFirstEnrollmentArgs {
  language?: Maybe<StudyLanguage>;
}

export interface UserPremiumArgs {
  legacy?: Maybe<Scalars['Boolean']>;
}

export interface UserClassBookingHistoryArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

export interface UserCompletedStudySessionsArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

export interface UserAsset {
  /** The ID of an object */
  id: Scalars['ID'];
  originalUrl?: Maybe<Scalars['String']>;
  processedUrl?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}

export interface UserEmailLookup {
  __typename?: 'UserEmailLookup';
  result?: Maybe<Array<User>>;
}

export interface UserIdLookup {
  __typename?: 'UserIdLookup';
  result?: Maybe<User>;
}

export enum UserLevelPreference {
  Foundation = 'FOUNDATION',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

export interface UserPath {
  __typename?: 'UserPath';
  id: Scalars['ID'];
  items: Array<SmartCourse>;
  currentCourse?: Maybe<SmartCourse>;
  state: UserPathState;
  name: Scalars['String'];
}

export enum UserPathState {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export interface UserPhoneLookup {
  __typename?: 'UserPhoneLookup';
  result?: Maybe<User>;
}

export enum UserTag {
  EfEmployee = 'EF_EMPLOYEE',
  EfHalloApp = 'EF_HALLO_APP',
}

export interface UtmParameters {
  campaign?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
}

export interface ValidateGooglePurchaseInput {
  token: Scalars['String'];
  productId: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ValidateGooglePurchasePayload {
  __typename?: 'ValidateGooglePurchasePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ValidatePurchaseInput {
  receipt: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface ValidatePurchasePayload {
  __typename?: 'ValidatePurchasePayload';
  viewer: Viewer;
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface VideoMessageItem {
  __typename?: 'VideoMessageItem';
  url: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  aspect?: Maybe<Scalars['Float']>;
  vocab?: Maybe<Array<VideoVocabItem>>;
  subtitles?: Maybe<Array<VideoMessageItemSubtitle>>;
  hideSubtitles?: Maybe<Scalars['Boolean']>;
}

export interface VideoMessageItemSubtitle {
  __typename?: 'VideoMessageItemSubtitle';
  /** The starting time of the subtitle text in ms */
  start: Scalars['Int'];
  /** The ending time of the subtitle text in ms */
  end: Scalars['Int'];
  /** Subtitle text */
  text: Scalars['String'];
}

export interface VideoReviewContentItem {
  __typename?: 'VideoReviewContentItem';
  url: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  aspect?: Maybe<Scalars['Float']>;
  vocab?: Maybe<Array<VideoReviewContentItemVideoVocab>>;
  subtitles?: Maybe<Array<VideoReviewContentSubtitle>>;
  hideSubtitles?: Maybe<Scalars['Boolean']>;
}

export interface VideoReviewContentItemVideoVocab {
  __typename?: 'VideoReviewContentItemVideoVocab';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
  audioUrl: Scalars['String'];
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
}

export interface VideoReviewContentSubtitle {
  __typename?: 'VideoReviewContentSubtitle';
  /** The starting time of the subtitle text in ms */
  start: Scalars['Int'];
  /** The ending time of the subtitle text in ms */
  end: Scalars['Int'];
  /** Subtitle text */
  text: Scalars['String'];
}

export interface VideoVocabItem {
  __typename?: 'VideoVocabItem';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
  audioUrl: Scalars['String'];
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface Viewer {
  __typename?: 'Viewer';
  /** The user's current daily checkin streak */
  checkinStreak?: Maybe<Scalars['Int']>;
  lastCheckinDate?: Maybe<Scalars['Date']>;
  pastDailyCheckins: Array<DailyCheckin>;
  /** Current progress for a user */
  currentProgress: Progress;
  /** The list of chats available to the user */
  chats: Array<Chat>;
  allBucketAssignments?: Maybe<Array<BucketAssignment>>;
  lockedInBucketAssignments?: Maybe<Array<BucketAssignment>>;
  paymentProvider?: Maybe<PaymentProvider>;
  reviewNotebooks: Array<ReviewNotebook>;
  admin?: Maybe<Admin>;
  availableClassTopics: Array<ClassTopic>;
  upcomingClassBookings: Array<ClassBooking>;
  inProgressClassBooking?: Maybe<ClassBooking>;
  classBookingHistory: ClassBookingConnection;
  /** Lookup a word in the dictionary */
  dictionaryLookup?: Maybe<DictionaryWord>;
  statistics: ViewerStatistics;
  oneSignal?: Maybe<ViewerOneSignal>;
  smartReview?: Maybe<SmartReview>;
  smartCourse: ViewerSmartCourse;
  learningPathCollection: LearningPathCollection;
  followedLearningPathCollection: LearningPathCollection;
  studyLanguages: Array<ViewerStudyLanguage>;
  intercom?: Maybe<ViewerIntercom>;
  roleplay: ViewerRoleplay;
  dailyPlan?: Maybe<DailyPlan>;
  userPath?: Maybe<UserPath>;
  userHasCompletedAllPaths: Scalars['Boolean'];
  dailyRanking?: Maybe<DailyRanking>;
  stories?: Maybe<Array<Story>>;
  showcaseLesson?: Maybe<ShowcaseLesson>;
  me?: Maybe<User>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerPastDailyCheckinsArgs {
  numDays?: Maybe<Scalars['Int']>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerClassBookingHistoryArgs {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerDictionaryLookupArgs {
  word: Scalars['String'];
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerSmartCourseArgs {
  language?: Maybe<StudyLanguage>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerLearningPathCollectionArgs {
  language?: Maybe<StudyLanguage>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerFollowedLearningPathCollectionArgs {
  language?: Maybe<StudyLanguage>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerRoleplayArgs {
  language?: Maybe<StudyLanguage>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerDailyPlanArgs {
  studyLanguage?: Maybe<StudyLanguage>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerUserPathArgs {
  studyLanguage?: Maybe<StudyLanguage>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerUserHasCompletedAllPathsArgs {
  studyLanguage?: Maybe<StudyLanguage>;
}

/** The viewer field represents the currently logged-in user; its subfields expose data that are contextual to the user */
export interface ViewerShowcaseLessonArgs {
  language?: Maybe<StudyLanguage>;
}

export interface ViewerIntercom {
  __typename?: 'ViewerIntercom';
  userHash?: Maybe<Scalars['String']>;
}

export interface ViewerOneSignal {
  __typename?: 'ViewerOneSignal';
  idHash: Scalars['String'];
  emailHash?: Maybe<Scalars['String']>;
}

export interface ViewerRoleplay {
  __typename?: 'ViewerRoleplay';
  /** Deprecated */
  trendingRoleplaysCollection?: Maybe<RoleplayCollection>;
  /** The list of collections available to the user */
  libraryCollections: Array<RoleplayLibraryItem>;
  /** Difficulty level collection */
  levelCollection?: Maybe<RoleplayCollection>;
}

export interface ViewerRoleplayLevelCollectionArgs {
  difficulty: SmartCourseDifficulty;
}

export interface ViewerSmartCourse {
  __typename?: 'ViewerSmartCourse';
  recommendedCoursesCollection?: Maybe<SmartCourseCollection>;
  enrolledCoursesCollection?: Maybe<SmartCourseCollection>;
  topicCollection?: Maybe<SmartCourseCollection>;
  libraryCollections?: Maybe<Array<SmartCourseCollection>>;
  nextUpCourse?: Maybe<SmartCourse>;
}

export interface ViewerSmartCourseTopicCollectionArgs {
  topic: SmartCourseTopic;
}

export interface ViewerSmartCourseNextUpCourseArgs {
  inProgressCourseId?: Maybe<Scalars['ID']>;
}

export interface ViewerStatistics {
  __typename?: 'ViewerStatistics';
  studyRecordsLength: Scalars['Int'];
}

export interface ViewerStudyLanguage {
  __typename?: 'ViewerStudyLanguage';
  language: StudyLanguage;
  addedAt?: Maybe<Scalars['DateTime']>;
}

export interface VocabLocation extends Node {
  __typename?: 'VocabLocation';
  /** The ID of an object */
  id: Scalars['ID'];
  sense?: Maybe<DictionarySense>;
  lesson?: Maybe<CoursewareLesson>;
  smartCourseLesson?: Maybe<SmartCourseLesson>;
  isSupplemental: Scalars['Boolean'];
}

export interface VocabularyReview {
  __typename?: 'VocabularyReview';
  /** The ID of an object */
  id: Scalars['ID'];
  chat?: Maybe<PracticeChat>;
  totalVocabLearned?: Maybe<Scalars['Int']>;
}

export interface VocabularyReviewTotalVocabLearnedArgs {
  since?: Maybe<Scalars['DateTime']>;
}

export interface VoucherIap {
  __typename?: 'VoucherIAP';
  premiumPricePlans: Array<PremiumPricePlanVoucher>;
  offerPlan?: Maybe<PremiumPricePlanVoucher>;
}

export enum VoucherProduct {
  FiveMinutes = 'FIVE_MINUTES',
  OneHour = 'ONE_HOUR',
  TwelveHours = 'TWELVE_HOURS',
  SevenDays = 'SEVEN_DAYS',
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  SixMonths = 'SIX_MONTHS',
  OneYear = 'ONE_YEAR',
  Lifetime = 'LIFETIME',
  FifteenMinutesTeacher = 'FIFTEEN_MINUTES_TEACHER',
  OneHourTeacher = 'ONE_HOUR_TEACHER',
  SevenDaysTeacher = 'SEVEN_DAYS_TEACHER',
  OneMonthTeacher = 'ONE_MONTH_TEACHER',
  ThreeMonthsTeacher = 'THREE_MONTHS_TEACHER',
  OneYearTeacher = 'ONE_YEAR_TEACHER',
  LifetimeTeacher = 'LIFETIME_TEACHER',
  OneYearTeacher_25Credits = 'ONE_YEAR_TEACHER_25_CREDITS',
  OneHourFamily = 'ONE_HOUR_FAMILY',
  OneHourWithDiscount = 'ONE_HOUR_WITH_DISCOUNT',
}

export enum VoucherSubscriptionProduct {
  FiveMinutes = 'FIVE_MINUTES',
  OneHour = 'ONE_HOUR',
  TwelveHours = 'TWELVE_HOURS',
  SevenDays = 'SEVEN_DAYS',
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
  SixMonths = 'SIX_MONTHS',
  OneYear = 'ONE_YEAR',
  Lifetime = 'LIFETIME',
  FifteenMinutesTeacher = 'FIFTEEN_MINUTES_TEACHER',
  OneHourTeacher = 'ONE_HOUR_TEACHER',
  SevenDaysTeacher = 'SEVEN_DAYS_TEACHER',
  OneMonthTeacher = 'ONE_MONTH_TEACHER',
  ThreeMonthsTeacher = 'THREE_MONTHS_TEACHER',
  OneYearTeacher = 'ONE_YEAR_TEACHER',
  LifetimeTeacher = 'LIFETIME_TEACHER',
  OneYearTeacher_25Credits = 'ONE_YEAR_TEACHER_25_CREDITS',
  OneHourFamily = 'ONE_HOUR_FAMILY',
  OneHourWithDiscount = 'ONE_HOUR_WITH_DISCOUNT',
}

export interface WebLinkMessageItem {
  __typename?: 'WebLinkMessageItem';
  url: Scalars['String'];
  title?: Maybe<WebLinkMessageItemTitle>;
}

export interface WebLinkMessageItemTitle {
  __typename?: 'WebLinkMessageItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface WebLinkReviewContentItem {
  __typename?: 'WebLinkReviewContentItem';
  url: Scalars['String'];
  title: WebLinkReviewContentItemTitle;
}

export interface WebLinkReviewContentItemTitle {
  __typename?: 'WebLinkReviewContentItemTitle';
  text: Scalars['String'];
  localizedText?: Maybe<Scalars['String']>;
}

export interface WordPronunciation {
  __typename?: 'WordPronunciation';
  word: Scalars['String'];
  pronunciation?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['String']>;
}

export interface WordPronunciationArg {
  word: Scalars['String'];
}

export type AuthRefreshAccessTokenMutationVariables = {
  input: AuthRefreshAccessTokenInput;
};

export type AuthRefreshAccessTokenMutation = {
  __typename: 'Mutation';
  authRefreshAccessToken: Maybe<{
    __typename: 'AuthRefreshAccessTokenPayload';
    accessToken: string;
    refreshToken: string;
  }>;
};

export type SmartCourseLessonQueryQueryVariables = {
  id: Scalars['ID'];
};

export type SmartCourseLessonQueryQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'VocabLocation' }
    | { __typename: 'DictionarySense' }
    | {
        __typename: 'SmartCourseLesson';
        course: { __typename: 'SmartCourse'; language: StudyLanguage };
      }
    | { __typename: 'SmartCourse' }
    | { __typename: 'SmartCourseSection' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'Roleplay' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'StudySession' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'SmartCourseCollection' }
    | { __typename: 'LearningPathCollection' }
    | { __typename: 'LearningPath' }
    | { __typename: 'RoleplayCollection' }
    | { __typename: 'RoleplayPlaylistCollection' }
    | { __typename: 'RoleplayPlaylist' }
    | { __typename: 'Story' }
    | { __typename: 'Gif' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type EnhanceSenseWithTranslationsMutationVariables = {
  input: AdminCreateTranslationsDictionaryEnhancementInput;
};

export type EnhanceSenseWithTranslationsMutation = {
  __typename: 'Mutation';
  adminCreateTranslationsDictionaryEnhancement: Maybe<{
    __typename: 'AdminCreateTranslationsDictionaryEnhancementPayload';
    word: Maybe<{
      __typename: 'DictionaryWord';
      id: string;
      senses: Array<
        {
          __typename: 'DictionarySense';
          id: string;
        } & DictionarySenseCardSenseFragment
      >;
    }>;
  }>;
};

export type EnhanceSenseInfoQueryVariables = {
  id: Scalars['ID'];
};

export type EnhanceSenseInfoQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'VocabLocation' }
    | ({
        __typename: 'DictionarySense';
        id: string;
        word: { __typename: 'DictionaryWord'; id: string; word: string };
      } & DictionarySenseCardSenseFragment)
    | { __typename: 'SmartCourseLesson' }
    | { __typename: 'SmartCourse' }
    | { __typename: 'SmartCourseSection' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'Roleplay' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'StudySession' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'SmartCourseCollection' }
    | { __typename: 'LearningPathCollection' }
    | { __typename: 'LearningPath' }
    | { __typename: 'RoleplayCollection' }
    | { __typename: 'RoleplayPlaylistCollection' }
    | { __typename: 'RoleplayPlaylist' }
    | { __typename: 'Story' }
    | { __typename: 'Gif' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type LexicalaLookupQueryVariables = {
  word: Scalars['String'];
};

export type LexicalaLookupQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminLexicalaSearch: Maybe<{
      __typename: 'AdminLexicalaSearch';
      results: Array<{
        __typename: 'LexicalaWord';
        headword: string;
        pos: Maybe<string>;
        senses: Array<
          Maybe<{
            __typename: 'LexicalaSense';
            id: string;
            definition: string;
            translations: Maybe<
              Array<{
                __typename: 'LexicalaTranslation';
                language: DictionaryLanguage;
                translation: string;
              }>
            >;
          }>
        >;
      }>;
    }>;
  }>;
};

export type DictionaryLookupQueryVariables = {
  word: Scalars['String'];
  lessonId: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
};

export type DictionaryLookupQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminDictionaryLookup: Array<{
      __typename: 'DictionaryWord';
      id: string;
      word: string;
      senses: Array<
        { __typename: 'DictionarySense' } & DictionarySenseCardSenseFragment
      >;
    }>;
  }>;
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'VocabLocation' }
    | { __typename: 'DictionarySense' }
    | {
        __typename: 'SmartCourseLesson';
        id: string;
        vocabLocations: Maybe<
          Array<{
            __typename: 'VocabLocation';
            id: string;
            sense: Maybe<{ __typename: 'DictionarySense'; id: string }>;
          }>
        >;
      }
    | { __typename: 'SmartCourse' }
    | { __typename: 'SmartCourseSection' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'Roleplay' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'StudySession' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'SmartCourseCollection' }
    | { __typename: 'LearningPathCollection' }
    | { __typename: 'LearningPath' }
    | { __typename: 'RoleplayCollection' }
    | { __typename: 'RoleplayPlaylistCollection' }
    | { __typename: 'RoleplayPlaylist' }
    | { __typename: 'Story' }
    | { __typename: 'Gif' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type TagVocabMutationVariables = {
  lessonId: Scalars['ID'];
  senseId: Scalars['ID'];
  isSupplemental?: Maybe<Scalars['Boolean']>;
  word?: Maybe<Scalars['String']>;
};

export type TagVocabMutation = {
  __typename: 'Mutation';
  adminCreateVocabLocation: Maybe<{
    __typename: 'AdminCreateVocabLocationPayload';
    vocabLocation: {
      __typename: 'VocabLocation';
      id: string;
      isSupplemental: boolean;
      lesson: Maybe<{
        __typename: 'CoursewareLesson';
        id: string;
        vocabLocations: Maybe<
          Array<
            {
              __typename: 'VocabLocation';
              isSupplemental: boolean;
            } & VocabLocationChipDataFragment
          >
        >;
      }>;
      smartCourseLesson: Maybe<{
        __typename: 'SmartCourseLesson';
        id: string;
        vocabLocations: Maybe<
          Array<
            {
              __typename: 'VocabLocation';
              isSupplemental: boolean;
            } & VocabLocationChipDataFragment
          >
        >;
      }>;
    };
  }>;
};

export type DictionarySenseCardSenseFragment = {
  __typename: 'DictionarySense';
  id: string;
  definition: string;
  pos: Maybe<string>;
  examples: Maybe<
    Array<{ __typename: 'DictionarySenseExample'; text: string }>
  >;
  word: { __typename: 'DictionaryWord'; id: string; word: string };
  adminEnhancements: Array<{
    __typename: 'TranslationsDictionaryEnhancement';
    source: DictionarySenseEnhancementSource;
    translations: Array<{
      __typename: 'TranslationsMapping';
      language: DictionaryLanguage;
      translations: Array<string>;
    }>;
  }>;
};

export type GetLevelsQueryVariables = {};

export type GetLevelsQuery = {
  __typename: 'Query';
  levels: Maybe<
    Array<
      Maybe<{
        __typename: 'Level';
        id: string;
        number: number;
        description: Maybe<string>;
      }>
    >
  >;
};

export type UserFragment = { __typename: 'User' } & UserInfoUserFragment &
  UserStudyHistoryUserFragment;

export type UserQueryVariables = {
  id: Scalars['ID'];
  canReadUserData?: Scalars['Boolean'];
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'VocabLocation' }
    | { __typename: 'DictionarySense' }
    | { __typename: 'SmartCourseLesson' }
    | { __typename: 'SmartCourse' }
    | { __typename: 'SmartCourseSection' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'Roleplay' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | ({ __typename: 'User' } & UserFragment)
    | { __typename: 'StudySession' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'SmartCourseCollection' }
    | { __typename: 'LearningPathCollection' }
    | { __typename: 'LearningPath' }
    | { __typename: 'RoleplayCollection' }
    | { __typename: 'RoleplayPlaylistCollection' }
    | { __typename: 'RoleplayPlaylist' }
    | { __typename: 'Story' }
    | { __typename: 'Gif' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type UserAdminPermissionsQueryVariables = {};

export type UserAdminPermissionsQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{
      __typename: 'Admin';
      id: string;
      permissions: Array<AdminPermission>;
      role: AdminRole;
    }>;
  };
};

export type UserBucketListUserFragment = {
  __typename: 'User';
  id: string;
  allBucketAssignments: Maybe<
    Array<{
      __typename: 'BucketAssignment';
      bucket: string;
      bucketGroup: string;
      availableBuckets: Array<Maybe<string>>;
    }>
  >;
  lockedInBucketAssignments: Maybe<
    Array<{
      __typename: 'BucketAssignment';
      bucket: string;
      bucketGroup: string;
    }>
  >;
};

export type AdminLockBucketAssignmentMutationVariables = {
  input: AdminLockBucketAssignmentInput;
};

export type AdminLockBucketAssignmentMutation = {
  __typename: 'Mutation';
  adminLockBucketAssignment: Maybe<{
    __typename: 'AdminLockBucketAssignmentPayload';
    user: Maybe<{ __typename: 'User' } & UserBucketListUserFragment>;
  }>;
};

export type UserInfoUserFragment = {
  __typename: 'User';
  id: string;
  name: Maybe<string>;
  region: Maybe<string>;
  created: Maybe<any>;
  isIdentified: Maybe<boolean>;
  authSource: Maybe<Array<Maybe<AuthSource>>>;
  countryCode: Maybe<string>;
  phoneNumber: Maybe<string>;
  email: Maybe<string>;
  birthday: Maybe<any>;
  isForgotten: boolean;
  progress: Maybe<{
    __typename: 'Progress';
    currentLevelNumber: Maybe<number>;
    points: Maybe<number>;
  }>;
  premium: Maybe<{
    __typename: 'Premium';
    isEnabled: boolean;
    endTime: Maybe<any>;
    planType: Maybe<PremiumPlanType>;
    paymentProviderName: Maybe<PaymentProviderName>;
    promoCode: Maybe<{ __typename: 'PromoCode'; title: Maybe<string> }>;
  }>;
  legacyPremium: Maybe<{
    __typename: 'Premium';
    isEnabled: boolean;
    endTime: Maybe<any>;
    planType: Maybe<PremiumPlanType>;
    paymentProviderName: Maybe<PaymentProviderName>;
    promoCode: Maybe<{ __typename: 'PromoCode'; title: Maybe<string> }>;
  }>;
  localizedContentLanguage: Maybe<{
    __typename: 'SupportedLocalizedContentLanguage';
    tag: string;
    name: string;
  }>;
} & UserBucketListUserFragment;

export type UserInfoUpgradeUserPremiumMutationVariables = {
  input: AdminUpgradeUserInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserInfoUpgradeUserPremiumMutation = {
  __typename: 'Mutation';
  adminUpgradeUser: Maybe<{
    __typename: 'AdminUpgradeUserPayload';
    user: Maybe<
      {
        __typename: 'User';
        id: string;
        premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
      } & UserInfoUserFragment
    >;
  }>;
};

export type UserInfoUpdateUserLevelMutationVariables = {
  input: AdminUpdateUserLevelInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserInfoUpdateUserLevelMutation = {
  __typename: 'Mutation';
  adminUpdateUserLevel: Maybe<{
    __typename: 'AdminUpdateUserLevelPayload';
    user: Maybe<{ __typename: 'User' } & UserInfoUserFragment>;
  }>;
};

export type AdminForgetUserMutationVariables = {
  input: AdminForgetUserInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type AdminForgetUserMutation = {
  __typename: 'Mutation';
  adminForgetUser: Maybe<{
    __typename: 'AdminForgetUserPayload';
    user: Maybe<{ __typename: 'User'; id: string } & UserInfoUserFragment>;
  }>;
};

export type AdminUpdateUserLocalizedContentLanguageMutationVariables = {
  input: AdminUpdateUserLocalizedContentLanguageInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type AdminUpdateUserLocalizedContentLanguageMutation = {
  __typename: 'Mutation';
  adminUpdateUserLocalizedContentLanguage: Maybe<{
    __typename: 'AdminUpdateUserLocalizedContentLanguagePayload';
    user: Maybe<{ __typename: 'User'; id: string } & UserInfoUserFragment>;
  }>;
};

export type AdminUpdateUserEmailMutationVariables = {
  input: AdminUpdateUserEmailInput;
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type AdminUpdateUserEmailMutation = {
  __typename: 'Mutation';
  adminUpdateUserEmail: Maybe<{
    __typename: 'AdminUpdateUserEmailPayload';
    user: Maybe<{ __typename: 'User'; id: string } & UserInfoUserFragment>;
  }>;
};

export type UserStudyHistoryUserFragment = { __typename: 'User'; id: string };

export type UserStudyHistoryQueryVariables = {
  userId: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
};

export type UserStudyHistoryQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | { __typename: 'CoursewareStudySection'; id: string }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'VocabLocation'; id: string }
    | { __typename: 'DictionarySense'; id: string }
    | { __typename: 'SmartCourseLesson'; id: string }
    | { __typename: 'SmartCourse'; id: string }
    | { __typename: 'SmartCourseSection'; id: string }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'Roleplay'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | {
        __typename: 'User';
        id: string;
        completedStudySessions: Maybe<{
          __typename: 'StudySessionConnection';
          edges: Maybe<
            Array<
              Maybe<{
                __typename: 'StudySessionEdge';
                node: Maybe<
                  {
                    __typename: 'StudySession';
                    id: string;
                  } & UserStudyHistoryRowStudySessionFragment
                >;
              }>
            >
          >;
          pageInfo: {
            __typename: 'PageInfo';
            endCursor: Maybe<string>;
            hasNextPage: boolean;
          };
        }>;
      }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'SmartCourseCollection'; id: string }
    | { __typename: 'LearningPathCollection'; id: string }
    | { __typename: 'LearningPath'; id: string }
    | { __typename: 'RoleplayCollection'; id: string }
    | { __typename: 'RoleplayPlaylistCollection'; id: string }
    | { __typename: 'RoleplayPlaylist'; id: string }
    | { __typename: 'Story'; id: string }
    | { __typename: 'Gif'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type UserStudyHistoryRowStudySessionFragment = {
  __typename: 'StudySession';
  id: string;
  score: Maybe<number>;
  completionTime: Maybe<any>;
  section:
    | { __typename: 'BaseStudySessionSectionContent'; type: string }
    | { __typename: 'CoursewareStudySessionSectionContent'; type: string }
    | {
        __typename: 'SmartCourseStudySessionSectionContent';
        type: string;
        section: Maybe<{
          __typename: 'SmartCourseSection';
          id: string;
          title: string;
          lesson: {
            __typename: 'SmartCourseLesson';
            id: string;
            title: string;
            course: {
              __typename: 'SmartCourse';
              id: string;
              title: string;
              difficulty: SmartCourseDifficulty;
            };
          };
        }>;
      };
};

export type VocabLocationChipDataFragment = {
  __typename: 'VocabLocation';
  id: string;
  sense: Maybe<{
    __typename: 'DictionarySense';
    id: string;
    word: { __typename: 'DictionaryWord'; id: string; word: string };
  }>;
};

export type HeaderViewerQueryQueryVariables = {};

export type HeaderViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{ __typename: 'Admin'; id: string; email: string }>;
  };
};

export type AudioReviewContentItemFragmentFragment = {
  __typename: 'AudioReviewContentItem';
  url: string;
  duration: Maybe<number>;
  hideTranscripts: Maybe<boolean>;
  transcripts: Maybe<
    Array<{
      __typename: 'AudioReviewContentItemTranscript';
      text: string;
      localizedText: Maybe<string>;
    }>
  >;
};

export type AudioCarouselReviewContentItemFragmentFragment = {
  __typename: 'AudioCarouselReviewContentItem';
  items: Array<
    {
      __typename: 'AudioReviewContentItem';
    } & AudioReviewContentItemFragmentFragment
  >;
};

export type CardReviewContentItemFragmentFragment = {
  __typename: 'CardReviewContentItem';
  title: {
    __typename: 'CardReviewContentItemTitle';
    text: string;
    localizedText: Maybe<string>;
  };
  items: Array<
    | {
        __typename: 'CardReviewContentItemBodyImage';
        url: string;
        aspect: Maybe<number>;
      }
    | {
        __typename: 'CardReviewContentItemBodyText';
        text: string;
        localizedText: Maybe<string>;
      }
  >;
  subtitle: Maybe<{
    __typename: 'CardReviewContentItemSubtitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  image: Maybe<{
    __typename: 'CardReviewContentItemImage';
    url: string;
    aspect: Maybe<number>;
  }>;
  audio: Maybe<{
    __typename: 'CardReviewContentItemAudio';
    url: string;
    duration: Maybe<number>;
  }>;
};

export type CardCarouselReviewContentItemFragmentFragment = {
  __typename: 'CardCarouselReviewContentItem';
  items: Array<
    | ({
        __typename: 'CardReviewContentItem';
      } & CardReviewContentItemFragmentFragment)
    | ({
        __typename: 'TextCardReviewContentItem';
      } & TextCardReviewContentItemFragmentFragment)
    | { __typename: 'ImageAudioCardReviewContentItem' }
  >;
};

type ReviewContentItemFragment_TextReviewContentItem_Fragment = {
  __typename: 'TextReviewContentItem';
} & TextReviewContentItemFragmentFragment;

type ReviewContentItemFragment_GifReviewContentItem_Fragment = {
  __typename: 'GifReviewContentItem';
} & GifReviewContentItemFragmentFragment;

type ReviewContentItemFragment_AudioReviewContentItem_Fragment = {
  __typename: 'AudioReviewContentItem';
} & AudioReviewContentItemFragmentFragment;

type ReviewContentItemFragment_AudioCarouselReviewContentItem_Fragment = {
  __typename: 'AudioCarouselReviewContentItem';
} & AudioCarouselReviewContentItemFragmentFragment;

type ReviewContentItemFragment_WebLinkReviewContentItem_Fragment = {
  __typename: 'WebLinkReviewContentItem';
} & WebLinkReviewContentItemFragmentFragment;

type ReviewContentItemFragment_VideoReviewContentItem_Fragment = {
  __typename: 'VideoReviewContentItem';
} & VideoReviewContentItemFragmentFragment;

type ReviewContentItemFragment_ImageReviewContentItem_Fragment = {
  __typename: 'ImageReviewContentItem';
} & ImageReviewContentItemFragmentFragment;

type ReviewContentItemFragment_CardReviewContentItem_Fragment = {
  __typename: 'CardReviewContentItem';
} & CardReviewContentItemFragmentFragment;

type ReviewContentItemFragment_TextCardReviewContentItem_Fragment = {
  __typename: 'TextCardReviewContentItem';
} & TextCardReviewContentItemFragmentFragment;

type ReviewContentItemFragment_CardCarouselReviewContentItem_Fragment = {
  __typename: 'CardCarouselReviewContentItem';
} & CardCarouselReviewContentItemFragmentFragment;

type ReviewContentItemFragment_ReadingReviewContentItem_Fragment = {
  __typename: 'ReadingReviewContentItem';
} & ReadingReviewContentItemFragmentFragment;

type ReviewContentItemFragment_TableReviewContentItem_Fragment = {
  __typename: 'TableReviewContentItem';
};

type ReviewContentItemFragment_ImageAudioCardReviewContentItem_Fragment = {
  __typename: 'ImageAudioCardReviewContentItem';
};

export type ReviewContentItemFragmentFragment =
  | ReviewContentItemFragment_TextReviewContentItem_Fragment
  | ReviewContentItemFragment_GifReviewContentItem_Fragment
  | ReviewContentItemFragment_AudioReviewContentItem_Fragment
  | ReviewContentItemFragment_AudioCarouselReviewContentItem_Fragment
  | ReviewContentItemFragment_WebLinkReviewContentItem_Fragment
  | ReviewContentItemFragment_VideoReviewContentItem_Fragment
  | ReviewContentItemFragment_ImageReviewContentItem_Fragment
  | ReviewContentItemFragment_CardReviewContentItem_Fragment
  | ReviewContentItemFragment_TextCardReviewContentItem_Fragment
  | ReviewContentItemFragment_CardCarouselReviewContentItem_Fragment
  | ReviewContentItemFragment_ReadingReviewContentItem_Fragment
  | ReviewContentItemFragment_TableReviewContentItem_Fragment
  | ReviewContentItemFragment_ImageAudioCardReviewContentItem_Fragment;

type CoursewareReviewSummaryContentItemFragment_ReviewTopic_Fragment = {
  __typename: 'ReviewTopic';
} & ReviewTopicFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_TextReviewContentItem_Fragment = {
  __typename: 'TextReviewContentItem';
} & TextReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_GifReviewContentItem_Fragment = {
  __typename: 'GifReviewContentItem';
} & GifReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_AudioReviewContentItem_Fragment = {
  __typename: 'AudioReviewContentItem';
} & AudioReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_AudioCarouselReviewContentItem_Fragment = {
  __typename: 'AudioCarouselReviewContentItem';
} & AudioCarouselReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_WebLinkReviewContentItem_Fragment = {
  __typename: 'WebLinkReviewContentItem';
} & WebLinkReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_VideoReviewContentItem_Fragment = {
  __typename: 'VideoReviewContentItem';
} & VideoReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_ImageReviewContentItem_Fragment = {
  __typename: 'ImageReviewContentItem';
} & ImageReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_CardReviewContentItem_Fragment = {
  __typename: 'CardReviewContentItem';
} & CardReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_TextCardReviewContentItem_Fragment = {
  __typename: 'TextCardReviewContentItem';
};

type CoursewareReviewSummaryContentItemFragment_CardCarouselReviewContentItem_Fragment = {
  __typename: 'CardCarouselReviewContentItem';
} & CardCarouselReviewContentItemFragmentFragment;

type CoursewareReviewSummaryContentItemFragment_ReadingReviewContentItem_Fragment = {
  __typename: 'ReadingReviewContentItem';
};

type CoursewareReviewSummaryContentItemFragment_TableReviewContentItem_Fragment = {
  __typename: 'TableReviewContentItem';
};

type CoursewareReviewSummaryContentItemFragment_ImageAudioCardReviewContentItem_Fragment = {
  __typename: 'ImageAudioCardReviewContentItem';
};

export type CoursewareReviewSummaryContentItemFragmentFragment =
  | CoursewareReviewSummaryContentItemFragment_ReviewTopic_Fragment
  | CoursewareReviewSummaryContentItemFragment_TextReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_GifReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_AudioReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_AudioCarouselReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_WebLinkReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_VideoReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_ImageReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_CardReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_TextCardReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_CardCarouselReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_ReadingReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_TableReviewContentItem_Fragment
  | CoursewareReviewSummaryContentItemFragment_ImageAudioCardReviewContentItem_Fragment;

export type ReviewExampleFragmentFragment = {
  __typename: 'ReviewExample';
  exampleTitle: Maybe<{
    __typename: 'ReviewExampleTitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  items: Array<
    | ({
        __typename: 'TextReviewContentItem';
      } & ReviewContentItemFragment_TextReviewContentItem_Fragment)
    | ({
        __typename: 'GifReviewContentItem';
      } & ReviewContentItemFragment_GifReviewContentItem_Fragment)
    | ({
        __typename: 'AudioReviewContentItem';
      } & ReviewContentItemFragment_AudioReviewContentItem_Fragment)
    | ({
        __typename: 'AudioCarouselReviewContentItem';
      } & ReviewContentItemFragment_AudioCarouselReviewContentItem_Fragment)
    | ({
        __typename: 'WebLinkReviewContentItem';
      } & ReviewContentItemFragment_WebLinkReviewContentItem_Fragment)
    | ({
        __typename: 'VideoReviewContentItem';
      } & ReviewContentItemFragment_VideoReviewContentItem_Fragment)
    | ({
        __typename: 'ImageReviewContentItem';
      } & ReviewContentItemFragment_ImageReviewContentItem_Fragment)
    | ({
        __typename: 'CardReviewContentItem';
      } & ReviewContentItemFragment_CardReviewContentItem_Fragment)
    | ({
        __typename: 'TextCardReviewContentItem';
      } & ReviewContentItemFragment_TextCardReviewContentItem_Fragment)
    | ({
        __typename: 'CardCarouselReviewContentItem';
      } & ReviewContentItemFragment_CardCarouselReviewContentItem_Fragment)
    | ({
        __typename: 'ReadingReviewContentItem';
      } & ReviewContentItemFragment_ReadingReviewContentItem_Fragment)
    | ({
        __typename: 'TableReviewContentItem';
      } & ReviewContentItemFragment_TableReviewContentItem_Fragment)
    | ({
        __typename: 'ImageAudioCardReviewContentItem';
      } & ReviewContentItemFragment_ImageAudioCardReviewContentItem_Fragment)
  >;
};

export type GifReviewContentItemFragmentFragment = {
  __typename: 'GifReviewContentItem';
  url: string;
  aspect: Maybe<number>;
};

export type ImageReviewContentItemFragmentFragment = {
  __typename: 'ImageReviewContentItem';
  url: string;
  aspect: Maybe<number>;
};

export type ReadingReviewContentItemFragmentFragment = {
  __typename: 'ReadingReviewContentItem';
  title: {
    __typename: 'CardReviewContentItemTitle';
    text: string;
    localizedText: Maybe<string>;
  };
  items: Array<
    | {
        __typename: 'CardReviewContentItemBodyImage';
        url: string;
        aspect: Maybe<number>;
      }
    | {
        __typename: 'CardReviewContentItemBodyText';
        text: string;
        localizedText: Maybe<string>;
      }
  >;
  subtitle: Maybe<{
    __typename: 'CardReviewContentItemSubtitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  image: Maybe<{
    __typename: 'CardReviewContentItemImage';
    url: string;
    aspect: Maybe<number>;
  }>;
};

export type TextReviewContentItemFragmentFragment = {
  __typename: 'TextReviewContentItem';
  text: string;
  localizedText: Maybe<string>;
};

export type TextCardReviewContentItemFragmentFragment = {
  __typename: 'TextCardReviewContentItem';
  items: Array<{
    __typename: 'CardReviewContentItemBodyText';
    text: string;
    localizedText: Maybe<string>;
  }>;
};

export type ReviewTopicFragmentFragment = {
  __typename: 'ReviewTopic';
  topicTitle: Maybe<{
    __typename: 'ReviewTopicTitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  body: Array<
    | ({ __typename: 'ReviewExample' } & ReviewExampleFragmentFragment)
    | ({
        __typename: 'TextReviewContentItem';
      } & TextReviewContentItemFragmentFragment)
    | ({
        __typename: 'GifReviewContentItem';
      } & GifReviewContentItemFragmentFragment)
    | ({
        __typename: 'AudioReviewContentItem';
      } & AudioReviewContentItemFragmentFragment)
    | ({
        __typename: 'AudioCarouselReviewContentItem';
      } & AudioCarouselReviewContentItemFragmentFragment)
    | ({
        __typename: 'WebLinkReviewContentItem';
      } & WebLinkReviewContentItemFragmentFragment)
    | ({
        __typename: 'VideoReviewContentItem';
      } & VideoReviewContentItemFragmentFragment)
    | ({
        __typename: 'ImageReviewContentItem';
      } & ImageReviewContentItemFragmentFragment)
    | ({
        __typename: 'CardReviewContentItem';
      } & CardReviewContentItemFragmentFragment)
    | { __typename: 'TextCardReviewContentItem' }
    | ({
        __typename: 'CardCarouselReviewContentItem';
      } & CardCarouselReviewContentItemFragmentFragment)
    | { __typename: 'ReadingReviewContentItem' }
    | { __typename: 'TableReviewContentItem' }
    | { __typename: 'ImageAudioCardReviewContentItem' }
  >;
};

export type VideoReviewContentItemFragmentFragment = {
  __typename: 'VideoReviewContentItem';
  url: string;
  aspect: Maybe<number>;
  duration: Maybe<number>;
};

export type WebLinkReviewContentItemFragmentFragment = {
  __typename: 'WebLinkReviewContentItem';
  url: string;
  title: {
    __typename: 'WebLinkReviewContentItemTitle';
    text: string;
    localizedText: Maybe<string>;
  };
};

export type AudioCarouselMessageItemFragmentFragment = {
  __typename: 'AudioCarouselMessageItem';
  items: Array<
    { __typename: 'AudioMessageItem' } & AudioMessageItemFragmentFragment
  >;
};

export type AudioMessageItemFragmentFragment = {
  __typename: 'AudioMessageItem';
  url: string;
  duration: Maybe<number>;
  hideTranscripts: Maybe<boolean>;
  transcripts: Maybe<
    Array<{
      __typename: 'AudioMessageItemTranscriptItem';
      text: string;
      localizedText: Maybe<string>;
    }>
  >;
};

export type CardCarouselMessageItemFragmentFragment = {
  __typename: 'CardCarouselMessageItem';
  items: Array<
    | ({ __typename: 'CardMessageItem' } & CardMessageItemFragmentFragment)
    | ({
        __typename: 'TextCardMessageItem';
      } & TextCardMessageItemFragmentFragment)
    | ({
        __typename: 'ImageAudioCardMessageItem';
      } & ImageAudioCardMessageItemFragmentFragment)
  >;
};

export type CardMessageItemFragmentFragment = {
  __typename: 'CardMessageItem';
  title: Maybe<{
    __typename: 'CardMessageItemTitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  items: Array<
    | {
        __typename: 'CardMessageItemBodyImage';
        url: string;
        aspect: Maybe<number>;
      }
    | {
        __typename: 'CardMessageItemBodyText';
        text: string;
        localizedText: Maybe<string>;
      }
  >;
  subtitle: Maybe<{
    __typename: 'CardMessageItemSubtitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  image: Maybe<{
    __typename: 'CardMessageItemImage';
    url: string;
    aspect: Maybe<number>;
  }>;
  audio: Maybe<{ __typename: 'CardMessageItemAudio'; url: string }>;
};

export type DialogFragmentFragment = {
  __typename: 'Dialog';
  segments: Array<
    | ({ __typename: 'DialogBaseSegment' } & DialogBaseSegmentFragmentFragment)
    | { __typename: 'DialogPaywallSegment' }
    | ({
        __typename: 'DialogQuestionSegment';
      } & DialogQuestionSegmentFragmentFragment)
    | ({
        __typename: 'DialogTopicSegment';
      } & DialogTopicSegmentFragmentFragment)
  >;
};

export type DialogBaseSegmentFragmentFragment = {
  __typename: 'DialogBaseSegment';
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
};

export type DialogMultipleChoiceTextQuestionFragmentFragment = {
  __typename: 'DialogMultipleChoiceTextQuestion';
  correctChoiceIndices: Array<number>;
  maxAttempts: Maybe<number>;
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
  choices: Array<{
    __typename: 'DialogMultipleChoiceTextChoice';
    text: string;
  }>;
};

export type DialogQuestionSegmentFragmentFragment = {
  __typename: 'DialogQuestionSegment';
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
  questions: Array<
    | ({
        __typename: 'DialogMultipleChoiceTextQuestion';
      } & DialogMultipleChoiceTextQuestionFragmentFragment)
    | { __typename: 'DialogScrambleQuestion' }
    | ({
        __typename: 'DialogSpeakingQuestion';
      } & DialogSpeakingQuestionFragmentFragment)
  >;
};

export type DialogSpeakingQuestionFragmentFragment = {
  __typename: 'DialogSpeakingQuestion';
  maxAttempts: Maybe<number>;
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
  content: { __typename: 'DialogSpeakingContent'; text: string };
};

export type DialogStatementFragmentFragment = {
  __typename: 'DialogStatement';
  identifier: string;
  item:
    | ({
        __typename: 'TextMessageItem';
      } & MessageItemFragment_TextMessageItem_Fragment)
    | ({
        __typename: 'GifMessageItem';
      } & MessageItemFragment_GifMessageItem_Fragment)
    | ({
        __typename: 'AudioMessageItem';
      } & MessageItemFragment_AudioMessageItem_Fragment)
    | ({
        __typename: 'AudioCarouselMessageItem';
      } & MessageItemFragment_AudioCarouselMessageItem_Fragment)
    | ({
        __typename: 'VideoMessageItem';
      } & MessageItemFragment_VideoMessageItem_Fragment)
    | ({
        __typename: 'WebLinkMessageItem';
      } & MessageItemFragment_WebLinkMessageItem_Fragment)
    | ({
        __typename: 'ImageMessageItem';
      } & MessageItemFragment_ImageMessageItem_Fragment)
    | ({
        __typename: 'CardMessageItem';
      } & MessageItemFragment_CardMessageItem_Fragment)
    | ({
        __typename: 'TextCardMessageItem';
      } & MessageItemFragment_TextCardMessageItem_Fragment)
    | ({
        __typename: 'CardCarouselMessageItem';
      } & MessageItemFragment_CardCarouselMessageItem_Fragment)
    | ({
        __typename: 'ReadingMessageItem';
      } & MessageItemFragment_ReadingMessageItem_Fragment)
    | ({
        __typename: 'TableMessageItem';
      } & MessageItemFragment_TableMessageItem_Fragment)
    | ({
        __typename: 'ImageAudioCardMessageItem';
      } & MessageItemFragment_ImageAudioCardMessageItem_Fragment);
  pauseChoice: Maybe<{ __typename: 'PauseChoiceTextOption'; text: string }>;
};

export type DialogTopicSegmentFragmentFragment = {
  __typename: 'DialogTopicSegment';
  statements: Array<
    { __typename: 'DialogStatement' } & DialogStatementFragmentFragment
  >;
  topic: {
    __typename: 'DialogSegmentTopic';
    text: string;
    localizedText: Maybe<string>;
  };
};

export type GifMessageItemFragmentFragment = {
  __typename: 'GifMessageItem';
  url: string;
  aspect: Maybe<number>;
};

export type ImageAudioCardMessageItemAudioFragmentFragment = {
  __typename: 'CardMessageItemAudio';
  url: string;
  duration: Maybe<number>;
  transcripts: Maybe<
    Array<{
      __typename: 'CardMessageItemTranscriptItem';
      text: string;
      localizedText: Maybe<string>;
    }>
  >;
};

export type ImageAudioCardMessageItemFragmentFragment = {
  __typename: 'ImageAudioCardMessageItem';
  audio: Maybe<
    {
      __typename: 'CardMessageItemAudio';
    } & ImageAudioCardMessageItemAudioFragmentFragment
  >;
  image: Maybe<{
    __typename: 'CardMessageItemImage';
    url: string;
    aspect: Maybe<number>;
  }>;
};

export type ImageMessageItemFragmentFragment = {
  __typename: 'ImageMessageItem';
  url: string;
  aspect: Maybe<number>;
};

type MessageItemFragment_TextMessageItem_Fragment = {
  __typename: 'TextMessageItem';
} & TextMessageItemFragmentFragment;

type MessageItemFragment_GifMessageItem_Fragment = {
  __typename: 'GifMessageItem';
} & GifMessageItemFragmentFragment;

type MessageItemFragment_AudioMessageItem_Fragment = {
  __typename: 'AudioMessageItem';
} & AudioMessageItemFragmentFragment;

type MessageItemFragment_AudioCarouselMessageItem_Fragment = {
  __typename: 'AudioCarouselMessageItem';
} & AudioCarouselMessageItemFragmentFragment;

type MessageItemFragment_VideoMessageItem_Fragment = {
  __typename: 'VideoMessageItem';
} & VideoMessageItemFragmentFragment;

type MessageItemFragment_WebLinkMessageItem_Fragment = {
  __typename: 'WebLinkMessageItem';
} & WebLinkMessageItemFragmentFragment;

type MessageItemFragment_ImageMessageItem_Fragment = {
  __typename: 'ImageMessageItem';
} & ImageMessageItemFragmentFragment;

type MessageItemFragment_CardMessageItem_Fragment = {
  __typename: 'CardMessageItem';
} & CardMessageItemFragmentFragment;

type MessageItemFragment_TextCardMessageItem_Fragment = {
  __typename: 'TextCardMessageItem';
} & TextCardMessageItemFragmentFragment;

type MessageItemFragment_CardCarouselMessageItem_Fragment = {
  __typename: 'CardCarouselMessageItem';
} & CardCarouselMessageItemFragmentFragment;

type MessageItemFragment_ReadingMessageItem_Fragment = {
  __typename: 'ReadingMessageItem';
} & ReadingMessageItemFragmentFragment;

type MessageItemFragment_TableMessageItem_Fragment = {
  __typename: 'TableMessageItem';
};

type MessageItemFragment_ImageAudioCardMessageItem_Fragment = {
  __typename: 'ImageAudioCardMessageItem';
} & ImageAudioCardMessageItemFragmentFragment;

export type MessageItemFragmentFragment =
  | MessageItemFragment_TextMessageItem_Fragment
  | MessageItemFragment_GifMessageItem_Fragment
  | MessageItemFragment_AudioMessageItem_Fragment
  | MessageItemFragment_AudioCarouselMessageItem_Fragment
  | MessageItemFragment_VideoMessageItem_Fragment
  | MessageItemFragment_WebLinkMessageItem_Fragment
  | MessageItemFragment_ImageMessageItem_Fragment
  | MessageItemFragment_CardMessageItem_Fragment
  | MessageItemFragment_TextCardMessageItem_Fragment
  | MessageItemFragment_CardCarouselMessageItem_Fragment
  | MessageItemFragment_ReadingMessageItem_Fragment
  | MessageItemFragment_TableMessageItem_Fragment
  | MessageItemFragment_ImageAudioCardMessageItem_Fragment;

export type ReadingMessageItemFragmentFragment = {
  __typename: 'ReadingMessageItem';
  title: Maybe<{
    __typename: 'CardMessageItemTitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  items: Array<
    | {
        __typename: 'CardMessageItemBodyImage';
        url: string;
        aspect: Maybe<number>;
      }
    | {
        __typename: 'CardMessageItemBodyText';
        text: string;
        localizedText: Maybe<string>;
      }
  >;
  subtitle: Maybe<{
    __typename: 'CardMessageItemSubtitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
  image: Maybe<{
    __typename: 'CardMessageItemImage';
    url: string;
    aspect: Maybe<number>;
  }>;
};

export type TextCardMessageItemFragmentFragment = {
  __typename: 'TextCardMessageItem';
  items: Array<{
    __typename: 'CardMessageItemBodyText';
    text: string;
    localizedText: Maybe<string>;
  }>;
};

export type TextMessageItemFragmentFragment = {
  __typename: 'TextMessageItem';
  text: string;
  localizedText: Maybe<string>;
};

export type VideoMessageItemFragmentFragment = {
  __typename: 'VideoMessageItem';
  url: string;
  aspect: Maybe<number>;
  duration: Maybe<number>;
  hideSubtitles: Maybe<boolean>;
  vocab: Maybe<
    Array<{
      __typename: 'VideoVocabItem';
      text: string;
      localizedText: Maybe<string>;
      audioUrl: string;
    }>
  >;
  subtitles: Maybe<
    Array<{ __typename: 'VideoMessageItemSubtitle'; text: string }>
  >;
};

export type WebLinkMessageItemFragmentFragment = {
  __typename: 'WebLinkMessageItem';
  url: string;
  title: Maybe<{
    __typename: 'WebLinkMessageItemTitle';
    text: string;
    localizedText: Maybe<string>;
  }>;
};

export type AdminDataFragment = {
  __typename: 'Admin';
  id: string;
  email: string;
  role: AdminRole;
  permissions: Array<AdminPermission>;
};

export type AdminUpdateAdminMutationVariables = {
  input: AdminUpdateAdminInput;
};

export type AdminUpdateAdminMutation = {
  __typename: 'Mutation';
  adminUpdateAdmin: Maybe<{
    __typename: 'AdminUpdateAdminPayload';
    admin: Maybe<{ __typename: 'Admin' } & AdminDataFragment>;
  }>;
};

export type AdminEmailLookupQueryVariables = {
  email: Scalars['String'];
};

export type AdminEmailLookupQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminEmailLookup: Maybe<{
      __typename: 'AdminEmailLookup';
      result: Maybe<{ __typename: 'Admin' } & AdminDataFragment>;
    }>;
  }>;
};

export type AdminUpdatePromoCodeMutationVariables = {
  input: AdminUpdatePromoCodeInput;
};

export type AdminUpdatePromoCodeMutation = {
  __typename: 'Mutation';
  adminUpdatePromoCode: Maybe<{
    __typename: 'AdminUpdatePromoCodePayload';
    promoCode: { __typename: 'PromoCode'; id: string; status: PromoCodeStatus };
  }>;
};

export type AdminCreatePromoCodeBatchMutationVariables = {
  input: AdminCreatePromoCodeBatchInput;
};

export type AdminCreatePromoCodeBatchMutation = {
  __typename: 'Mutation';
  adminCreatePromoCodeBatch: Maybe<{
    __typename: 'AdminCreatePromoCodeBatchPayload';
    filename: string;
  }>;
};

export type AdminCreatePromoCodeMutationVariables = {
  input: AdminCreatePromoCodeInput;
};

export type AdminCreatePromoCodeMutation = {
  __typename: 'Mutation';
  adminCreatePromoCode: Maybe<{
    __typename: 'AdminCreatePromoCodePayload';
    promoCode: { __typename: 'PromoCode' } & PromoCodeInfoFragment;
  }>;
};

export type PromoCodeInfoFragment = {
  __typename: 'PromoCode';
  id: string;
  code: string;
  maxRedemptions: Maybe<number>;
  redemptionsRemaining: Maybe<number>;
  title: Maybe<string>;
  created: any;
  product: string;
  status: PromoCodeStatus;
};

export type GetAdminPromoCodesQueryVariables = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sourceKind?: Maybe<PromoCodeSourceKind>;
};

export type GetAdminPromoCodesQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminPromoCodesLookup: {
      __typename: 'PromoCodeConnection';
      edges: Maybe<
        Array<
          Maybe<{
            __typename: 'PromoCodeEdge';
            node: Maybe<{ __typename: 'PromoCode' } & PromoCodeInfoFragment>;
          }>
        >
      >;
      pageInfo: {
        __typename: 'PageInfo';
        hasNextPage: boolean;
        endCursor: Maybe<string>;
      };
    };
  }>;
};

export type AdminRedeemPromoCodeMutationVariables = {
  input: AdminRedeemPromoCodeWithPhoneNumberInput;
  canReadSensitiveUserData?: Maybe<Scalars['Boolean']>;
};

export type AdminRedeemPromoCodeMutation = {
  __typename: 'Mutation';
  adminRedeemPromoCodeWithPhoneNumber: Maybe<{
    __typename: 'AdminRedeemPromoCodeWithPhoneNumberPayload';
    user: { __typename: 'User' } & UserInfoUserFragment;
  }>;
};

export type DictionarySearchPageSearchQueryVariables = {
  word: Scalars['String'];
  language?: Maybe<Scalars['String']>;
};

export type DictionarySearchPageSearchQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    adminDictionaryLookup: Array<{
      __typename: 'DictionaryWord';
      id: string;
      word: string;
      senses: Array<
        { __typename: 'DictionarySense' } & DictionarySenseCardSenseFragment
      >;
    }>;
  }>;
};

export type DictionarySensePageInformationQueryVariables = {
  id: Scalars['ID'];
};

export type DictionarySensePageInformationQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'VocabLocation' }
    | {
        __typename: 'DictionarySense';
        id: string;
        definition: string;
        pos: Maybe<string>;
        word: { __typename: 'DictionaryWord'; id: string; word: string };
        inflections: Maybe<
          Array<{
            __typename: 'DictionarySenseInflection';
            text: string;
            type: string;
          }>
        >;
        examples: Maybe<
          Array<{ __typename: 'DictionarySenseExample'; text: string }>
        >;
        translations: Maybe<
          Array<{
            __typename: 'DictionaryTranslation';
            language: Maybe<DictionaryLanguage>;
            word: string;
            translation: string;
          }>
        >;
        adminEnhancements: Array<
          {
            __typename: 'TranslationsDictionaryEnhancement';
            id: string;
          } & AdminEnhancementsRowEnhancementFragment
        >;
      }
    | { __typename: 'SmartCourseLesson' }
    | { __typename: 'SmartCourse' }
    | { __typename: 'SmartCourseSection' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'Roleplay' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'StudySession' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'SmartCourseCollection' }
    | { __typename: 'LearningPathCollection' }
    | { __typename: 'LearningPath' }
    | { __typename: 'RoleplayCollection' }
    | { __typename: 'RoleplayPlaylistCollection' }
    | { __typename: 'RoleplayPlaylist' }
    | { __typename: 'Story' }
    | { __typename: 'Gif' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type AdminEnhancementsRowEnhancementFragment = {
  __typename: 'TranslationsDictionaryEnhancement';
  id: string;
  source: DictionarySenseEnhancementSource;
  translations: Array<{
    __typename: 'TranslationsMapping';
    language: DictionaryLanguage;
    translations: Array<string>;
  }>;
};

export type AdminDeleteDictionaryEnhancementMutationVariables = {
  input: AdminDeleteDictionaryEnhancementInput;
};

export type AdminDeleteDictionaryEnhancementMutation = {
  __typename: 'Mutation';
  adminDeleteDictionaryEnhancement: Maybe<{
    __typename: 'AdminDeleteDictionaryEnhancementPayload';
    clientMutationId: Maybe<string>;
    sense: Maybe<{
      __typename: 'DictionarySense';
      id: string;
      adminEnhancements: Array<
        {
          __typename: 'TranslationsDictionaryEnhancement';
          id: string;
        } & AdminEnhancementsRowEnhancementFragment
      >;
    }>;
  }>;
};

export type AddVocabInfoToSmartCourseSectionQueryVariables = {
  id: Scalars['ID'];
};

export type AddVocabInfoToSmartCourseSectionQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | { __typename: 'CoursewareStudySection'; id: string }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'VocabLocation'; id: string }
    | { __typename: 'DictionarySense'; id: string }
    | { __typename: 'SmartCourseLesson'; id: string }
    | { __typename: 'SmartCourse'; id: string }
    | {
        __typename: 'SmartCourseSection';
        vocabSuggestions: Maybe<Array<string>>;
        id: string;
        lesson: { __typename: 'SmartCourseLesson'; id: string };
      }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'Roleplay'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | { __typename: 'User'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'SmartCourseCollection'; id: string }
    | { __typename: 'LearningPathCollection'; id: string }
    | { __typename: 'LearningPath'; id: string }
    | { __typename: 'RoleplayCollection'; id: string }
    | { __typename: 'RoleplayPlaylistCollection'; id: string }
    | { __typename: 'RoleplayPlaylist'; id: string }
    | { __typename: 'Story'; id: string }
    | { __typename: 'Gif'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type SmartCourseSectionLoadConversationQueryVariables = {
  id: Scalars['ID'];
};

export type SmartCourseSectionLoadConversationQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | { __typename: 'CoursewareStudySection'; id: string }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'VocabLocation'; id: string }
    | { __typename: 'DictionarySense'; id: string }
    | { __typename: 'SmartCourseLesson'; id: string }
    | { __typename: 'SmartCourse'; id: string }
    | {
        __typename: 'SmartCourseSection';
        id: string;
        content: {
          __typename: 'SmartCourseSectionContent';
          theme: Maybe<{
            __typename: 'SmartCourseSectionTheme';
            text: string;
            localizedText: Maybe<string>;
          }>;
          dialog: { __typename: 'Dialog' } & DialogFragmentFragment;
        };
      }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'Roleplay'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | { __typename: 'User'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'SmartCourseCollection'; id: string }
    | { __typename: 'LearningPathCollection'; id: string }
    | { __typename: 'LearningPath'; id: string }
    | { __typename: 'RoleplayCollection'; id: string }
    | { __typename: 'RoleplayPlaylistCollection'; id: string }
    | { __typename: 'RoleplayPlaylist'; id: string }
    | { __typename: 'Story'; id: string }
    | { __typename: 'Gif'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type LoadSmartCourseSectionInfoQueryVariables = {
  id: Scalars['ID'];
};

export type LoadSmartCourseSectionInfoQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | { __typename: 'CoursewareStudySection'; id: string }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'VocabLocation'; id: string }
    | { __typename: 'DictionarySense'; id: string }
    | { __typename: 'SmartCourseLesson'; id: string }
    | { __typename: 'SmartCourse'; id: string }
    | {
        __typename: 'SmartCourseSection';
        title: string;
        description: Maybe<string>;
        sectionType: Maybe<SmartCourseSectionType>;
        vocabSuggestions: Maybe<Array<string>>;
        id: string;
        lesson: {
          __typename: 'SmartCourseLesson';
          id: string;
          title: string;
          numberInCourse: number;
          course: {
            __typename: 'SmartCourse';
            id: string;
            title: string;
            language: StudyLanguage;
          };
        };
      }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'Roleplay'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | { __typename: 'User'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'SmartCourseCollection'; id: string }
    | { __typename: 'LearningPathCollection'; id: string }
    | { __typename: 'LearningPath'; id: string }
    | { __typename: 'RoleplayCollection'; id: string }
    | { __typename: 'RoleplayPlaylistCollection'; id: string }
    | { __typename: 'RoleplayPlaylist'; id: string }
    | { __typename: 'Story'; id: string }
    | { __typename: 'Gif'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type SmartCourseSectionNavQueryVariables = {};

export type SmartCourseSectionNavQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    smartCourses: Maybe<
      Array<{
        __typename: 'SmartCourse';
        id: string;
        lessons: Array<{
          __typename: 'SmartCourseLesson';
          id: string;
          sections: Array<{
            __typename: 'SmartCourseSection';
            id: string;
            sectionType: Maybe<SmartCourseSectionType>;
          }>;
        }>;
      }>
    >;
  }>;
};

export type SmartCourseLoadSummaryQueryVariables = {
  id: Scalars['ID'];
};

export type SmartCourseLoadSummaryQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin'; id: string }
    | { __typename: 'CoursewareStudySection'; id: string }
    | { __typename: 'CoursewareLesson'; id: string }
    | { __typename: 'Level'; id: string }
    | { __typename: 'CoursewareCourse'; id: string }
    | { __typename: 'VocabLocation'; id: string }
    | { __typename: 'DictionarySense'; id: string }
    | { __typename: 'SmartCourseLesson'; id: string }
    | { __typename: 'SmartCourse'; id: string }
    | {
        __typename: 'SmartCourseSection';
        id: string;
        reviewSummaryContents: Array<
          | ({
              __typename: 'ReviewTopic';
            } & CoursewareReviewSummaryContentItemFragment_ReviewTopic_Fragment)
          | ({
              __typename: 'TextReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_TextReviewContentItem_Fragment)
          | ({
              __typename: 'GifReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_GifReviewContentItem_Fragment)
          | ({
              __typename: 'AudioReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_AudioReviewContentItem_Fragment)
          | ({
              __typename: 'AudioCarouselReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_AudioCarouselReviewContentItem_Fragment)
          | ({
              __typename: 'WebLinkReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_WebLinkReviewContentItem_Fragment)
          | ({
              __typename: 'VideoReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_VideoReviewContentItem_Fragment)
          | ({
              __typename: 'ImageReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_ImageReviewContentItem_Fragment)
          | ({
              __typename: 'CardReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_CardReviewContentItem_Fragment)
          | ({
              __typename: 'TextCardReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_TextCardReviewContentItem_Fragment)
          | ({
              __typename: 'CardCarouselReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_CardCarouselReviewContentItem_Fragment)
          | ({
              __typename: 'ReadingReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_ReadingReviewContentItem_Fragment)
          | ({
              __typename: 'TableReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_TableReviewContentItem_Fragment)
          | ({
              __typename: 'ImageAudioCardReviewContentItem';
            } & CoursewareReviewSummaryContentItemFragment_ImageAudioCardReviewContentItem_Fragment)
        >;
      }
    | { __typename: 'ReviewLesson'; id: string }
    | { __typename: 'ReviewNote'; id: string }
    | { __typename: 'ReviewNotePracticeStudySection'; id: string }
    | { __typename: 'ReviewNotebook'; id: string }
    | { __typename: 'Roleplay'; id: string }
    | { __typename: 'ClassTopic'; id: string }
    | { __typename: 'ClassBooking'; id: string }
    | { __typename: 'ClassBookingNote'; id: string }
    | { __typename: 'User'; id: string }
    | { __typename: 'StudySession'; id: string }
    | { __typename: 'PracticeChat'; id: string }
    | { __typename: 'StudySetChat'; id: string }
    | { __typename: 'StudySet'; id: string }
    | { __typename: 'SmartCourseCollection'; id: string }
    | { __typename: 'LearningPathCollection'; id: string }
    | { __typename: 'LearningPath'; id: string }
    | { __typename: 'RoleplayCollection'; id: string }
    | { __typename: 'RoleplayPlaylistCollection'; id: string }
    | { __typename: 'RoleplayPlaylist'; id: string }
    | { __typename: 'Story'; id: string }
    | { __typename: 'Gif'; id: string }
    | { __typename: 'AssistantChat'; id: string }
    | { __typename: 'BugReportingChat'; id: string }
    | { __typename: 'CoursewareChat'; id: string }
    | { __typename: 'FeedbackChat'; id: string }
    | { __typename: 'LessonChat'; id: string }
    | { __typename: 'PremiumSampleChat'; id: string }
    | { __typename: 'TeacherChat'; id: string }
  >;
};

export type AdminSmartCoursesQueryVariables = {};

export type AdminSmartCoursesQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    smartCourses: Maybe<
      Array<
        {
          __typename: 'SmartCourse';
          id: string;
          title: string;
          difficulty: SmartCourseDifficulty;
          language: StudyLanguage;
        } & SmartCourseTableRowSmartCourseFragment
      >
    >;
  }>;
};

export type SmartCourseLessonNavQueryVariables = {};

export type SmartCourseLessonNavQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    smartCourses: Maybe<
      Array<{
        __typename: 'SmartCourse';
        id: string;
        lessons: Array<{ __typename: 'SmartCourseLesson'; id: string }>;
      }>
    >;
  }>;
};

export type SmartCourseLessonQueryVariables = {
  id: Scalars['ID'];
};

export type SmartCourseLessonQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'VocabLocation' }
    | { __typename: 'DictionarySense' }
    | {
        __typename: 'SmartCourseLesson';
        id: string;
        title: string;
        numberInCourse: number;
        vocabSuggestions: Maybe<Array<string>>;
        supplementalVocabSuggestions: Maybe<Array<string>>;
        course: {
          __typename: 'SmartCourse';
          id: string;
          title: string;
          language: StudyLanguage;
        };
        sections: Array<{
          __typename: 'SmartCourseSection';
          id: string;
          title: string;
          numberInLesson: number;
        }>;
        vocabLocations: Maybe<
          Array<
            {
              __typename: 'VocabLocation';
              isSupplemental: boolean;
            } & VocabLocationChipDataFragment
          >
        >;
        roleplays: Maybe<
          Array<{
            __typename: 'Roleplay';
            id: string;
            title: string;
            description: string;
            imageUrl: Maybe<string>;
            userRole: string;
            userGoal: string;
          }>
        >;
      }
    | { __typename: 'SmartCourse' }
    | { __typename: 'SmartCourseSection' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'Roleplay' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'StudySession' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'SmartCourseCollection' }
    | { __typename: 'LearningPathCollection' }
    | { __typename: 'LearningPath' }
    | { __typename: 'RoleplayCollection' }
    | { __typename: 'RoleplayPlaylistCollection' }
    | { __typename: 'RoleplayPlaylist' }
    | { __typename: 'Story' }
    | { __typename: 'Gif' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type RemoveVocabLocationMutationVariables = {
  id: Scalars['ID'];
};

export type RemoveVocabLocationMutation = {
  __typename: 'Mutation';
  adminDeleteVocabLocation: Maybe<{
    __typename: 'AdminDeleteVocabLocationPayload';
    clientMutationId: Maybe<string>;
  }>;
};

export type SmartCourseQueryVariables = {
  id: Scalars['ID'];
};

export type SmartCourseQuery = {
  __typename: 'Query';
  node: Maybe<
    | { __typename: 'DailyCheckin' }
    | { __typename: 'CoursewareStudySection' }
    | { __typename: 'CoursewareLesson' }
    | { __typename: 'Level' }
    | { __typename: 'CoursewareCourse' }
    | { __typename: 'VocabLocation' }
    | { __typename: 'DictionarySense' }
    | { __typename: 'SmartCourseLesson' }
    | {
        __typename: 'SmartCourse';
        id: string;
        title: string;
        language: StudyLanguage;
        difficulty: SmartCourseDifficulty;
        topics: Array<SmartCourseTopic>;
        lessons: Array<{
          __typename: 'SmartCourseLesson';
          id: string;
          title: string;
          numberInCourse: number;
        }>;
      }
    | { __typename: 'SmartCourseSection' }
    | { __typename: 'ReviewLesson' }
    | { __typename: 'ReviewNote' }
    | { __typename: 'ReviewNotePracticeStudySection' }
    | { __typename: 'ReviewNotebook' }
    | { __typename: 'Roleplay' }
    | { __typename: 'ClassTopic' }
    | { __typename: 'ClassBooking' }
    | { __typename: 'ClassBookingNote' }
    | { __typename: 'User' }
    | { __typename: 'StudySession' }
    | { __typename: 'PracticeChat' }
    | { __typename: 'StudySetChat' }
    | { __typename: 'StudySet' }
    | { __typename: 'SmartCourseCollection' }
    | { __typename: 'LearningPathCollection' }
    | { __typename: 'LearningPath' }
    | { __typename: 'RoleplayCollection' }
    | { __typename: 'RoleplayPlaylistCollection' }
    | { __typename: 'RoleplayPlaylist' }
    | { __typename: 'Story' }
    | { __typename: 'Gif' }
    | { __typename: 'AssistantChat' }
    | { __typename: 'BugReportingChat' }
    | { __typename: 'CoursewareChat' }
    | { __typename: 'FeedbackChat' }
    | { __typename: 'LessonChat' }
    | { __typename: 'PremiumSampleChat' }
    | { __typename: 'TeacherChat' }
  >;
};

export type SmartCourseTableRowSmartCourseFragment = {
  __typename: 'SmartCourse';
  id: string;
  title: string;
  difficulty: SmartCourseDifficulty;
  language: StudyLanguage;
  lessons: Array<{
    __typename: 'SmartCourseLesson';
    id: string;
    title: string;
    numberInCourse: number;
  }>;
};

export type AuthViewerQueryVariables = {};

export type AuthViewerQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{ __typename: 'Admin'; id: string; email: string }>;
  };
};

export type IndexViewerQueryQueryVariables = {};

export type IndexViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{
      __typename: 'Admin';
      id: string;
      permissions: Array<AdminPermission>;
    }>;
  };
};

export type UserLookupViewerQueryQueryVariables = {};

export type UserLookupViewerQueryQuery = {
  __typename: 'Query';
  viewer: {
    __typename: 'Viewer';
    admin: Maybe<{
      __typename: 'Admin';
      id: string;
      email: string;
      permissions: Array<AdminPermission>;
    }>;
  };
};

export type UserPhoneLookupQueryQueryVariables = {
  countryCode: Scalars['String'];
  phoneNumber: Scalars['String'];
  canReadUserData?: Scalars['Boolean'];
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserPhoneLookupQueryQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    userPhoneLookup: Maybe<{
      __typename: 'UserPhoneLookup';
      result: Maybe<
        {
          __typename: 'User';
          id: string;
          premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
        } & UserFragment
      >;
    }>;
  }>;
};

export type UserIdLookupQueryQueryVariables = {
  userId: Scalars['String'];
  canReadUserData?: Scalars['Boolean'];
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserIdLookupQueryQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    userIdLookup: Maybe<{
      __typename: 'UserIdLookup';
      result: Maybe<
        {
          __typename: 'User';
          id: string;
          premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
        } & UserFragment
      >;
    }>;
  }>;
};

export type UserEmailLookupQueryQueryVariables = {
  email: Scalars['String'];
  canReadUserData?: Scalars['Boolean'];
  canReadSensitiveUserData?: Scalars['Boolean'];
};

export type UserEmailLookupQueryQuery = {
  __typename: 'Query';
  admin: Maybe<{
    __typename: 'AdminQuery';
    userEmailLookup: Maybe<{
      __typename: 'UserEmailLookup';
      result: Maybe<
        Array<
          {
            __typename: 'User';
            id: string;
            premium: Maybe<{ __typename: 'Premium'; isEnabled: boolean }>;
          } & UserFragment
        >
      >;
    }>;
  }>;
};
